@use '../../fundation/index' as *;
/*  c-head01
---------------------------------------- */
.c-head01{
	position: relative;
	margin: 0 0 30px;
	padding-left: 20px;
	font-size: font-size(25);
	line-height: 1.6;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		width: 5px;
		top: 5px;
		bottom: 5px;
		background: $clr_primary;
	}
	@include mq (medium) {
		margin: 0 0 40px;
		padding-left: 30px;
		font-size: font-size(28);
		&::before{
			top: 5px;
			bottom: 3px;
		}
	}
}