@charset "UTF-8";
/*  color
================================================ */
/*  font
================================================ */
/*  line-height
================================================ */
/*  clamp vwの計算
================================================ */
/*  transition
================================================ */
/*  width
================================================ */
/*  tag design
================================================ */
/*  reset
================================================ */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
}

html {
  word-break: break-all;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

img, picture, video, canvas, svg {
  width: 100%;
  vertical-align: bottom;
}

input, button, textarea, select {
  font: inherit;
}

span {
  font-weight: inherit;
}

a {
  color: inherit;
}

a[class] {
  text-decoration: none;
}

mark {
  background-color: #ff9;
  font-weight: bold;
}

blockquote, q {
  quotes: none;
}

ul[class],
ol[class] {
  list-style: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

#root, #__next {
  isolation: isolate;
}

input[type=text], input[type=email], input[type=tel], textarea, button, select, option {
  display: block;
  width: 100%;
  font: inherit;
  outline: none;
  border-radius: 0;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

/*  base
================================================ */
html {
  overflow: auto;
}
html:not([lang=ja]) {
  word-break: break-word;
}

body {
  overflow: hidden;
  min-width: 320px;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  color: #0E101C;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 0.9375rem;
}
@media all and (min-width: 768px) {
  body {
    min-width: auto;
    font-size: 1rem;
  }
}

/*  l-header
---------------------------------------- */
.l-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 100;
}
.l-header.is-active {
  position: fixed;
  background-color: #fff;
}
.l-header .l-header__info {
  position: relative;
  height: 100%;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #fff;
  z-index: 2;
}
.l-header .l-header__logo {
  width: 101px;
}
.l-header .l-header__logo-link {
  display: block;
}
.l-header .l-header__nav-btn {
  position: relative;
  width: 32px;
  height: 32px;
  background: #fff;
}
.l-header .l-header__nav-btn-line {
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  background: #0E101C;
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: all;
}
.l-header .l-header__nav-btn-line:nth-child(1) {
  top: 5px;
  width: 16px;
}
.l-header .l-header__nav-btn-line:nth-child(2) {
  top: 15px;
  width: 24px;
}
.l-header .l-header__nav-btn-line:nth-child(3) {
  top: 25px;
  width: 32px;
}
.l-header .l-header__nav-btn.is-open .l-header__nav-btn-line:nth-child(1) {
  width: 35px;
  top: 15px;
  rotate: 45deg;
}
.l-header .l-header__nav-btn.is-open .l-header__nav-btn-line:nth-child(2) {
  opacity: 0;
}
.l-header .l-header__nav-btn.is-open .l-header__nav-btn-line:nth-child(3) {
  width: 35px;
  top: 15px;
  rotate: -45deg;
}
@media all and (min-width: 1320px) {
  .l-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 140px;
    padding: 80px 4% 0px;
  }
  .l-header.is-active {
    padding-top: 50px;
    height: 110px;
  }
  .l-header .l-header__info,
.l-header .l-header__logo {
    width: 169px;
  }
  .l-header .l-header__nav-btn {
    display: none;
  }
}

/*  l-nav
---------------------------------------- */
.l-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  background: #1D1D21;
  z-index: 1;
}
.l-nav .l-nav__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.l-nav .l-nav__item {
  text-align: center;
  color: #F7F7F7;
}
.l-nav .l-nav__item-link {
  display: block;
  padding: 20px 0;
  letter-spacing: 0.08em;
  font-size: 1.875rem;
}
@media all and (min-width: 1320px) {
  .l-nav {
    display: block !important;
    position: static;
    top: 0;
    left: 0;
    right: auto;
    height: auto;
    display: flex;
    background: transparent;
    padding-top: 0;
  }
  .l-nav .l-nav__list {
    flex-direction: row;
    gap: 40px;
  }
  .l-nav .l-nav__item {
    color: #0E101C;
  }
  .l-nav .l-nav__item-link {
    padding: 0;
    font-size: 1rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
  .l-nav .l-nav__item-link:hover {
    color: #0077EF;
  }
}

.l-footer {
  position: relative;
  padding-top: 50px;
  z-index: 1;
}
.l-footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #1D1D21;
  z-index: -1;
}
.l-footer .l-footer__info {
  padding-bottom: 50px;
}
.l-footer .l-footer__info-logo {
  width: 168px;
}
.l-footer .l-footer__info-logo-link {
  display: block;
}
.l-footer .l-footer__info-add {
  margin-top: 15px;
  font-size: 0.8125rem;
  color: #616165;
  line-height: 1.56;
}
.l-footer .l-footer__contact {
  margin-top: 35px;
  color: #F7F7F7;
}
.l-footer .l-footer__contact-txt {
  font-size: 0.875rem;
}
.l-footer .l-footer__contact-btn {
  margin-top: 20px;
}
.l-footer .l-footer__cr {
  border-top: 1px solid #252529;
  padding: 15px 0;
  text-align: center;
  color: #616165;
  font-size: 0.625rem;
}
@media all and (min-width: 768px) {
  .l-footer {
    padding-top: 120px;
  }
  .l-footer .l-footer__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 120px;
  }
  .l-footer .l-footer__info {
    width: 450px;
    padding-bottom: 0;
  }
  .l-footer .l-footer__nav {
    width: calc(100% - 450px);
  }
  .l-footer .l-footer__info-add {
    margin-top: 30px;
  }
  .l-footer .l-footer__contact {
    margin-top: 60px;
  }
  .l-footer .l-footer__contact-txt {
    font-size: 0.9375rem;
  }
  .l-footer .l-footer__contact-btn {
    margin-top: 30px;
  }
  .l-footer .l-footer__nav-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 25px;
    color: #F7F7F7;
  }
  .l-footer .l-footer__nav-item-link {
    display: block;
    padding: 5px 10px;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: opacity;
  }
  .l-footer .l-footer__nav-item-link:hover {
    opacity: 0.5;
  }
  .l-footer .l-footer__cr {
    padding: 20px 0;
  }
}

/*
---------------------------------------- */
.l-footer__en {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-83%);
  overflow: hidden;
  display: flex;
  width: 100%;
  margin-bottom: -0.5em;
  z-index: -2;
}
.l-footer__en .l-footer__en-in {
  display: inline-block;
  padding-right: 0.5em;
  white-space: nowrap;
  font-size: 2.8125rem;
  line-height: 1.1;
  transform: rotate(0.1deg);
}
.l-footer__en .l-footer__en-in:nth-child(1) {
  animation: loop 80s -40s linear infinite;
}
.l-footer__en .l-footer__en-in:nth-child(2) {
  animation: loop2 80s linear infinite;
}
@media all and (min-width: 768px) {
  .l-footer__en {
    margin-bottom: -1.1em;
  }
  .l-footer__en .l-footer__en-in {
    font-size: 6.25rem;
  }
}

@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
/*  sub-mv
---------------------------------------- */
.l-sub-mv__area {
  position: relative;
  padding: 110px 0 70px;
}
@media all and (min-width: 768px) {
  .l-sub-mv__area {
    padding: 280px 0 180px;
  }
}

/*  .l-sub-mv
---------------------------------------- */
.l-sub-mv .l-sub-mv__title-en {
  font-size: 3.5rem;
  letter-spacing: 0.08em;
  line-height: 1.1;
}
.l-sub-mv .l-sub-mv__title-ja {
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 700;
}
.l-sub-mv .l-sub-mv__title.l-sub-mv__title--large .l-sub-mv__title-ja {
  font-size: 2rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
}
@media all and (min-width: 768px) {
  .l-sub-mv .l-sub-mv__title-en {
    font-size: 7.5rem;
  }
  .l-sub-mv .l-sub-mv__title-ja {
    margin-top: 50px;
    font-size: 1.375rem;
  }
  .l-sub-mv .l-sub-mv__title.l-sub-mv__title--large .l-sub-mv__title-ja {
    font-size: 3.75rem;
  }
}

/*  SP左右余白あり、PCコンテンツ幅
---------------------------------------- */
.c-inner01 {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (min-width: 1320px) {
  .c-inner01 {
    width: 100%;
    max-width: 1320px;
    margin: auto;
    padding: 0;
  }
}

@media all and (min-width: 1320px) {
  .c-inner01.c-inner01--large {
    max-width: 1680px;
    padding: 0 24px;
  }
}

@media all and (min-width: 768px) {
  .c-inner01.c-inner01--small {
    max-width: 1010px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

/*  SP左右余白あり、PCはデバイス幅いっぱい
---------------------------------------- */
.c-inner02 {
  box-sizing: border-box;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
@media all and (min-width: 1320px) {
  .c-inner02 {
    max-width: min(100%, 1920px);
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

/*  SP左右余白「なし」、PCコンテンツ幅
---------------------------------------- */
.c-inner03 {
  box-sizing: border-box;
  width: 100%;
}
@media all and (min-width: 1320px) {
  .c-inner03 {
    max-width: min(100%, 1920px);
    margin: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

/*  c-head01
---------------------------------------- */
.c-head01 {
  position: relative;
  margin: 0 0 30px;
  padding-left: 20px;
  font-size: 1.5625rem;
  line-height: 1.6;
}
.c-head01::before {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  top: 5px;
  bottom: 5px;
  background: #0077EF;
}
@media all and (min-width: 768px) {
  .c-head01 {
    margin: 0 0 40px;
    padding-left: 30px;
    font-size: 1.75rem;
  }
  .c-head01::before {
    top: 5px;
    bottom: 3px;
  }
}

/* c-btn01
---------------------------------------- */
.c-btn01 {
  width: min(100%, 270px);
  margin: 40px auto 0;
}
.c-btn01 .c-btn01__link {
  overflow: hidden;
  position: relative;
  display: block;
  padding: 20px;
  border: 1px solid #0E101C;
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
}
.c-btn01 .c-btn01__link::before {
  content: "";
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 14px;
  height: 14px;
  background: #0077EF;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
@media all and (min-width: 768px) {
  .c-btn01 {
    width: min(100%, 500px);
    margin-top: 100px;
  }
  .c-btn01 .c-btn01__link {
    padding: 35px 40px;
    font-size: 1.125rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: border-color color;
  }
  .c-btn01 .c-btn01__link:hover {
    color: #0077EF;
    border-color: #0077EF;
  }
  .c-btn01 .c-btn01__link::before {
    width: 21px;
    height: 21px;
  }
}

/*  c-btn02
---------------------------------------- */
.c-btn02 {
  margin-top: 40px;
}
.c-btn02 .c-btn02__link {
  position: relative;
  display: inline-block;
  padding-right: 25px;
  letter-spacing: 0.08em;
  font-size: 0.9375rem;
}
.c-btn02 .c-btn02__link::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0.3em;
  width: 11px;
  height: 11px;
  background: #0077EF;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
@media all and (min-width: 768px) {
  .c-btn02 {
    margin-top: 60px;
  }
  .c-btn02 .c-btn02__link {
    padding-right: 35px;
    font-size: 1.0625rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
  .c-btn02 .c-btn02__link:hover {
    color: #0077EF;
  }
  .c-btn02 .c-btn02__link::before {
    width: 13px;
    height: 13px;
  }
}

/*  card
---------------------------------------- */
.c-card01 .c-card01__link {
  display: block;
}
.c-card01 .c-card01__img {
  overflow: hidden;
  border: 1px solid #DBDBDB;
}
.c-card01 .c-card01__txt-area {
  padding-top: 20px;
}
.c-card01 .c-card01__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px 12px;
  margin-bottom: 10px;
}
.c-card01 .c-card01__cats {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-card01 .c-card01__cat {
  display: inline-block;
  padding: 4px 12px;
  background: #1D1D21;
  color: #fff;
  font-size: 0.75rem;
}
.c-card01 .c-card01__date {
  color: #616165;
  font-size: 0.8125rem;
}
.c-card01 .c-card01__ttl {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.c-card01 .c-card01__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  margin-top: 15px;
}
.c-card01 .c-card01__tags {
  color: #616165;
  font-size: 0.75rem;
  line-height: 1.2;
}
@media all and (min-width: 768px) {
  .c-card01 {
    width: 350px;
  }
  .c-card01 .c-card01__img img {
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: transform;
  }
  .c-card01 .c-card01__txt-area {
    padding-top: 25px;
  }
  .c-card01 .c-card01__ttl {
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
  .c-card01 .c-card01__tags {
    gap: 5px 15px;
    margin-top: 20px;
  }
  .c-card01 .c-card01__tags {
    font-size: 0.875rem;
  }
  .c-card01 .c-card01__link:hover .c-card01__img img {
    transform: scale(1.1) rotate(3deg);
  }
  .c-card01 .c-card01__link:hover .c-card01__ttl {
    color: #0077EF;
  }
}
@media all and (min-width: 1320px) {
  .c-card01 .c-card01__cat {
    font-size: 0.9375rem;
  }
  .c-card01 .c-card01__ttl {
    font-size: 1.25rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
}

/* js-fadein-img
---------------------------------------- */
.js-fadein-img {
  opacity: 0;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  transition: all 0.6s ease-in-out;
}
.js-fadein-img.is-active {
  opacity: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.js-fadein-item {
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
}
.js-fadein-item.is-active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* js-fadein-img
---------------------------------------- */
.js-following.is-active {
  position: fixed !important;
  top: 0 !important;
}

/*  breadcrumb
---------------------------------------- */
.c-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  color: #616165;
  font-size: 0.8125rem;
}
.c-breadcrumb span, .c-breadcrumb a {
  display: block;
}
.c-breadcrumb a {
  text-decoration: none;
  display: block;
  color: #0E101C;
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: opacity;
}
.c-breadcrumb a:hover {
  opacity: 0.7;
}
.c-breadcrumb .c-breadcrumb__item {
  display: flex;
  align-items: center;
}
.c-breadcrumb .c-breadcrumb__item:nth-child(n+2) {
  margin-left: 12px;
}
.c-breadcrumb .c-breadcrumb__item:nth-child(n+2)::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 12px;
  margin-right: 12px;
  background: #DBDBDB;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

/*  table
---------------------------------------- */
.c-table01 table {
  table-layout: fixed;
  border: solid #0E101C;
  border-width: 1px 0 0 1px;
}
.c-table01 th, .c-table01 td {
  display: block;
  padding: 20px 25px;
  border: solid #0E101C;
  border-width: 0 1px 1px 0;
  text-align: left;
}
.c-table01 th {
  background: #1D1D21;
  color: #fff;
  font-size: 0.9375rem;
  font-weight: 700;
}
.c-table01 td {
  line-height: 1.8;
}
@media all and (min-width: 768px) {
  .c-table01 table {
    border: 0;
  }
  .c-table01 th, .c-table01 td {
    display: table-cell;
    padding: 35px 40px;
    border: 1px solid #0E101C;
  }
  .c-table01 th {
    width: 25%;
    text-align: center;
    font-size: 1rem;
    border-bottom: 1px solid #616165;
  }
}

/*  list
---------------------------------------- */
.c-list01 .c-list01__item {
  position: relative;
  padding-left: 17px;
}
.c-list01 .c-list01__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  width: 6px;
  height: 3px;
  background: #0077EF;
}
@media all and (min-width: 768px) {
  .c-list01 .c-list01__item {
    padding-left: 19px;
  }
  .c-list01 .c-list01__item::before {
    top: 12px;
  }
}

/*  layout
---------------------------------------- */
.c-layout-set01 .c-layout-set01__num {
  margin-bottom: 10px;
  color: #0077EF;
  font-size: 0.75rem;
  line-height: 1.1;
}
.c-layout-set01 .c-layout-set01__img {
  margin: 10px 0 0 -20px;
}
.c-layout-set01 .c-layout-set01__txt-area {
  padding-top: 30px;
}
.c-layout-set01 .c-layout-set01__head {
  margin-bottom: 10px;
  color: #0077EF;
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.6;
}
.c-layout-set01.c-layout-set01--rev .c-layout-set01__img {
  margin: 10px -20px 0 0;
}
@media all and (min-width: 768px) {
  .c-layout-set01 {
    display: grid;
    grid-template-columns: 50% auto;
    align-items: center;
    grid-template-areas: "img num" "img txt";
    gap: 0 7%;
  }
  .c-layout-set01 .c-layout-set01__num {
    grid-area: num;
    align-self: end;
    margin-bottom: 20px;
    font-size: 0.875rem;
  }
  .c-layout-set01 .c-layout-set01__img {
    grid-area: img;
    margin: 0;
  }
  .c-layout-set01 .c-layout-set01__txt-area {
    grid-area: txt;
    align-self: start;
    padding-top: 0;
  }
  .c-layout-set01 .c-layout-set01__head {
    margin-bottom: 30px;
    font-size: 2.3125rem;
  }
  .c-layout-set01.c-layout-set01--rev {
    grid-template-columns: auto 50%;
    grid-template-areas: "num img" "txt img";
  }
}

/*  c-form01
---------------------------------------- */
.c-form01 > * {
  font-size: min(16px, 1rem);
}
.c-form01 .c-form01__in:nth-child(n+2) {
  margin-top: 60px;
}
.c-form01 .c-form01__in dt, .c-form01 .c-form01__in-head {
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6;
}
.c-form01 .c-form01__in-privacy {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.c-form01 .c-form01__in-privacy-link {
  color: #616165;
  font-size: 0.875rem;
  font-weight: 500;
}
.c-form01 input, .c-form01 textarea {
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 0;
  border-bottom: 1px solid #0E101C;
  background: transparent;
}
.c-form01 input[type=text],
.c-form01 input[type=email],
.c-form01 textarea {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0;
}
.c-form01 textarea {
  height: 200px;
  padding: 15px 20px;
  border: 1px solid #0E101C;
}
.c-form01 input::placeholder {
  color: #616165;
}
.c-form01 input:-ms-input-placeholder {
  color: #616165;
}
.c-form01 input::-ms-input-placeholder {
  color: #616165;
}
.c-form01 button {
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
}
.c-form01 label {
  cursor: pointer;
}
.c-form01 input[type=checkbox] {
  display: none;
}
.c-form01 input[type=checkbox] + span {
  position: relative;
  display: inline-block;
  padding-left: 37px;
}
.c-form01 input[type=checkbox] + span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border: 1px solid #0E101C;
}
.c-form01 input[type=checkbox]:checked + span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: #0077EF;
}
@media all and (min-width: 768px) {
  .c-form01 .c-form01__in dt {
    margin-bottom: 18px;
  }
  .c-form01 .c-form01__in-privacy {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .c-form01 .c-form01__in-privacy-link a:hover {
    text-decoration: none;
  }
}

/*  .c-form01__btn
---------------------------------------- */
.c-form01__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 50px;
}
.c-form01__btn button {
  width: min(100%, 270px);
  padding: 20px;
  border: 1px solid #0E101C;
  background: #fff;
  text-align: center;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #0E101C;
}
.c-form01__btn button[value=back] {
  border-color: #616165;
  background: #616165;
  color: #F7F7F7;
}
@media all and (min-width: 768px) {
  .c-form01__btn {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 80px;
  }
  .c-form01__btn button {
    width: min(100%, 350px);
    padding: 25px;
    font-size: 1rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color border;
  }
  .c-form01__btn button:hover {
    border-color: #0077EF;
    color: #0077EF;
  }
  .c-form01__btn button:hover[value=back] {
    border-color: #616165;
    color: #616165;
    background: transparent;
  }
}

/* c-pager01
---------------------------------------- */
.c-pager01 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px 0;
  margin-top: 40px;
}
.c-pager01 li {
  width: 45px;
  height: 45px;
}
.c-pager01 li > * {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}
.c-pager01 li a {
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: color;
}
.c-pager01 li a:hover {
  color: #0077EF;
}
.c-pager01 li + li {
  margin-left: -1px;
}
.c-pager01 .page,
.c-pager01 .current {
  border: 1px solid #0E101C;
}
.c-pager01 .current span {
  background: #1D1D21;
  color: #fff;
}
.c-pager01 .previous a,
.c-pager01 .next a {
  position: relative;
  overflow: hidden;
  text-indent: 90px;
  white-space: nowrap;
}
.c-pager01 .previous a::before,
.c-pager01 .next a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 12px;
  background: #1D1D21;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: background;
}
.c-pager01 .previous a:hover::before,
.c-pager01 .next a:hover::before {
  background: #0077EF;
}
.c-pager01 .previous a::before {
  transform: translate(-50%, -50%) scale(-1, 1);
}
@media all and (min-width: 768px) {
  .c-pager01 {
    margin-top: 80px;
  }
  .c-pager01 li {
    width: 50px;
    height: 50px;
  }
  .c-pager01 .previous a::before,
.c-pager01 .next a::before {
    width: 8px;
    height: 16px;
  }
}

/*  c-pager02
---------------------------------------- */
.c-pager02 {
  position: relative;
  margin-top: 50px;
  padding-top: 55px;
}
.c-pager02::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 1px;
  height: 30px;
  background: #DBDBDB;
}
.c-pager02 a {
  text-decoration: none;
}
.c-pager02 .c-pager02__back {
  width: min(100%, 270px);
  margin: 0 auto;
}
.c-pager02 .c-pager02__back a {
  display: block;
  padding: 20px;
  border: 1px solid #0E101C;
  text-align: center;
  letter-spacing: 0.08em;
}
.c-pager02 .c-pager02__prev,
.c-pager02 .c-pager02__next {
  position: absolute;
  top: 0;
}
.c-pager02 .c-pager02__prev a,
.c-pager02 .c-pager02__next a {
  position: relative;
  display: block;
  padding: 10px 20px;
}
.c-pager02 .c-pager02__prev a::before,
.c-pager02 .c-pager02__next a::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 12px;
  background: #0E101C;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}
.c-pager02 .c-pager02__prev {
  left: 0;
}
.c-pager02 .c-pager02__prev a::before {
  left: 0;
  transform: translateY(-50%) scale(-1, 1);
}
.c-pager02 .c-pager02__next {
  right: 0;
}
.c-pager02 .c-pager02__next a::before {
  right: 0;
}
@media all and (min-width: 768px) {
  .c-pager02 {
    margin-top: 80px;
    padding-top: 0;
  }
  .c-pager02::before {
    content: none;
  }
  .c-pager02 .c-pager02__back {
    width: min(100%, 350px);
  }
  .c-pager02 .c-pager02__back a {
    padding: 25px;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color border-color;
  }
  .c-pager02 .c-pager02__back a:hover {
    color: #0077EF;
    border-color: #0077EF;
  }
  .c-pager02 .c-pager02__prev,
.c-pager02 .c-pager02__next {
    top: 50%;
    transform: translateY(-50%);
  }
  .c-pager02 .c-pager02__prev a,
.c-pager02 .c-pager02__next a {
    padding: 10px 35px;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
  .c-pager02 .c-pager02__prev a::before,
.c-pager02 .c-pager02__next a::before {
    width: 8px;
    height: 16px;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: background;
  }
  .c-pager02 .c-pager02__prev a:hover,
.c-pager02 .c-pager02__next a:hover {
    color: #0077EF;
  }
  .c-pager02 .c-pager02__prev a:hover::before,
.c-pager02 .c-pager02__next a:hover::before {
    background: #0077EF;
  }
}

.c-tax-select {
  width: min(100%, 300px);
  margin: 0 auto 35px;
  padding: 15px 40px 15px 20px;
  background: url(../img/common/ico-select.svg) no-repeat center right 20px/12px auto;
  border: 1px solid #0E101C;
  cursor: pointer;
  appearance: none;
}
.c-tax-select::-ms-expand {
  display: none;
}

/*  c-tax-keyword
---------------------------------------- */
.c-tax-keyword {
  margin-top: 70px;
}
.c-tax-keyword .c-tax-keyword__head {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 5px 15px;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  color: #fff;
}
.c-tax-keyword .c-tax-keyword__head::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}
.c-tax-keyword .c-tax-keyword__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  padding: 25px;
  border: 1px solid #DBDBDB;
}
.c-tax-keyword .c-tax-keyword__item--current .c-tax-keyword__item-link {
  color: #0077EF;
}
.c-tax-keyword .c-tax-keyword__item-link {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #616165;
}
@media all and (min-width: 1320px) {
  .c-tax-keyword {
    margin-top: 0;
  }
  .c-tax-keyword .c-tax-keyword__head {
    margin-bottom: 15px;
    padding: 8px 17px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 0.875rem;
  }
  .c-tax-keyword .c-tax-keyword__head::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #1D1D21;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7px), calc(100% - 7px) 100%, 0 100%);
  }
  .c-tax-keyword .c-tax-keyword__list {
    gap: 5px 15px;
    padding: 0;
    border: 0;
  }
  .c-tax-keyword .c-tax-keyword__item-link {
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: color;
  }
  .c-tax-keyword .c-tax-keyword__item-link:hover {
    color: #0077EF;
  }
}

/*  c-tax-category
---------------------------------------- */
.c-tax-category .c-tax-category__head {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 5px 15px;
  color: #fff;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  line-height: 1.5;
  position: relative;
  z-index: 1;
  color: #fff;
}
.c-tax-category .c-tax-category__head::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}
.c-tax-category .c-tax-category__list {
  border-top: 1px solid #DBDBDB;
}
.c-tax-category .c-tax-category__item {
  border-bottom: 1px solid #DBDBDB;
}
.c-tax-category .c-tax-category__item-link {
  position: relative;
  display: block;
  padding: 20px 20px 20px 38px;
  font-size: 0.875rem;
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: opacity;
}
.c-tax-category .c-tax-category__item-link::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  width: 5px;
  height: 10px;
  background: #DBDBDB;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
  transition-property: background;
}
.c-tax-category .c-tax-category__item-link:hover {
  opacity: 0.7;
}
.c-tax-category .c-tax-category__item--current .c-tax-category__item-link {
  color: #0077EF;
  font-weight: 700;
}
.c-tax-category .c-tax-category__item--current .c-tax-category__item-link::before {
  background: #0077EF;
}
@media all and (min-width: 768px) {
  .c-tax-category .c-tax-category__head {
    margin-bottom: 15px;
    padding: 8px 17px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 0.875rem;
  }
  .c-tax-category .c-tax-category__head::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #1D1D21;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7px), calc(100% - 7px) 100%, 0 100%);
  }
}

/*  single
---------------------------------------- */
.c-single01 {
  padding-bottom: 124px;
}
.c-single01 .c-single01__article {
  padding-bottom: 70px;
}
.c-single01 .c-single01__box {
  margin: 0 -20px;
  padding: 50px 20px 40px;
  background: #F7F7F7;
}
.c-single01 .c-single01__head {
  margin-bottom: 30px;
}
.c-single01 .c-single01__meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px 10px;
  margin-bottom: 15px;
}
.c-single01 .c-single01__cats {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.c-single01 .c-single01__cat {
  padding: 5px 10px;
  background: #1D1D21;
  color: #fff;
  font-size: 0.75rem;
}
.c-single01 .c-single01__data {
  color: #616165;
  font-size: 0.8125rem;
}
.c-single01 .c-single01__ttl {
  padding-bottom: 15px;
  border-bottom: 1px solid #0E101C;
  font-size: 1.75rem;
  line-height: 1.59;
  font-weight: 700;
}
.c-single01 .c-single01__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  gap: 5px 10px;
  font-size: 0.75rem;
  color: #616165;
}
.c-single01 .c-single01__thumb {
  border: 1px solid #DBDBDB;
}
.c-single01 .c-single01__cont {
  margin-top: 20px;
}
.c-single01 .c-single01__cont > * {
  margin-top: 25px;
}
.c-single01 .c-single01__cont > *:first-child {
  margin-top: 0;
}
.c-single01 .c-single01__cont img {
  width: 100%;
}
.c-single01 .c-single01__cont h2, .c-single01 .c-single01__cont h3, .c-single01 .c-single01__cont h4 {
  clear: both;
}
.c-single01 .c-single01__cont h2 {
  position: relative;
  margin: 60px 0 30px;
  padding-left: 20px;
  font-size: 1.5625rem;
  line-height: 1.6;
}
.c-single01 .c-single01__cont h2::before {
  content: "";
  position: absolute;
  left: 0;
  width: 5px;
  top: 5px;
  bottom: 5px;
  background: #0077EF;
}
.c-single01 .c-single01__cont h3 {
  margin: 30px 0 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #DBDBDB;
  font-size: 1.375rem;
  font-weight: 700;
}
.c-single01 .c-single01__cont h4 {
  margin: 30px 0 20px;
  font-size: 1.1875rem;
}
.c-single01 .c-single01__cont blockquote {
  position: relative;
  padding: 25px 40px;
  background: #fff;
}
.c-single01 .c-single01__cont blockquote::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 25px;
  width: 17px;
  height: 15px;
  background: url(../img/common/ico-blockquote.svg) no-repeat center/contain;
}
.c-single01 .c-single01__cont ul, .c-single01 .c-single01__cont ol {
  list-style: none;
}
.c-single01 .c-single01__cont ul li, .c-single01 .c-single01__cont ol li {
  position: relative;
}
.c-single01 .c-single01__cont ul li:nth-child(n+2), .c-single01 .c-single01__cont ol li:nth-child(n+2) {
  margin-top: 5px;
}
.c-single01 .c-single01__cont ul li {
  position: relative;
  padding-left: 17px;
}
.c-single01 .c-single01__cont ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 6px;
  height: 3px;
  background: #0077EF;
}
.c-single01 .c-single01__cont ol {
  counter-reset: item;
}
.c-single01 .c-single01__cont ol li {
  text-indent: -1.4em;
  padding-left: 1.4em;
}
.c-single01 .c-single01__cont ol li::before {
  counter-increment: item;
  content: counter(item) ". ";
  color: #0077EF;
  font-size: 0.9375rem;
  font-weight: 700;
}
.c-single01 .c-single01__cont strong, .c-single01 .c-single01__cont b {
  font-weight: 700;
  color: #F05C1A;
}
.c-single01 .c-single01__cont a {
  color: #0077EF;
}
.c-single01 .c-single01__cont a[target=_blank]::after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  width: 15px;
  height: 12px;
  background: url(../img/common/ico-blank.svg) no-repeat center/contain;
}
.c-single01 .c-single01__cont .c-single01__note {
  position: relative;
  margin-top: 10px;
  color: #616165;
  font-size: 0.875rem;
  text-indent: -1em;
  padding-left: 1em;
}
.c-single01 .c-single01__cont .c-single01__note::before {
  content: "※";
}
.c-single01 .c-single01__cont .c-single01__marker {
  display: inline-block;
  background: linear-gradient(transparent 65%, #B5DAFF 65%);
}
.c-single01 .c-single01__cont .c-single01__cont-box {
  padding: 25px 40px;
  background: #fff;
}
.c-single01 .c-single01__sns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 70px;
}
.c-single01 .c-single01__sns-item {
  width: 20px;
}
.c-single01 .c-single01__sns-item-in {
  appearance: none;
  border: 0;
  outline: 0;
  background: transparent;
}
.c-single01 .c-single01__sns-item-link {
  display: block;
}
@media all and (min-width: 768px) {
  .c-single01 {
    padding-bottom: 297px;
  }
  .c-single01 .c-single01__box {
    margin: 0;
    padding: 80px 90px 50px;
  }
  .c-single01 .c-single01__head {
    margin-bottom: 50px;
  }
  .c-single01 .c-single01__meta {
    gap: 5px 18px;
    margin-bottom: 20px;
  }
  .c-single01 .c-single01__cat {
    padding: 3px 13px;
    font-size: 0.9375rem;
  }
  .c-single01 .c-single01__data {
    font-size: 0.9375rem;
  }
  .c-single01 .c-single01__ttl {
    padding-bottom: 35px;
    font-size: 2rem;
  }
  .c-single01 .c-single01__tags {
    margin-top: 30px;
    gap: 5px 12px;
    font-size: 0.8125rem;
  }
  .c-single01 .c-single01__thumb {
    width: fit-content;
    margin: 0 auto;
  }
  .c-single01 .c-single01__thumb img {
    max-width: 100%;
    width: auto;
  }
  .c-single01 .c-single01__cont {
    margin-top: 40px;
  }
  .c-single01 .c-single01__cont > * {
    margin-top: 20px;
  }
  .c-single01 .c-single01__cont .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .c-single01 .c-single01__cont .aligncenter img {
    display: block;
    margin: 0 auto;
  }
  .c-single01 .c-single01__cont .alighleft {
    float: left;
    padding: 0 40px 40px 0;
  }
  .c-single01 .c-single01__cont .alignright {
    float: right;
    padding: 0 0 40px 40px;
  }
  .c-single01 .c-single01__cont img {
    max-width: 100%;
    width: auto;
  }
  .c-single01 .c-single01__cont h2 {
    margin: 80px 0 40px;
    padding-left: 30px;
    font-size: 1.75rem;
  }
  .c-single01 .c-single01__cont h2::before {
    top: 5px;
    bottom: 3px;
  }
  .c-single01 .c-single01__cont h3 {
    margin: 60px 0 30px;
    padding-bottom: 22px;
    font-size: 1.5rem;
  }
  .c-single01 .c-single01__cont h4 {
    margin: 60px 0 30px;
    font-size: 1.25rem;
  }
  .c-single01 .c-single01__cont blockquote {
    padding: 28px 70px;
  }
  .c-single01 .c-single01__cont blockquote::before {
    left: 25px;
    top: 20px;
    width: 24px;
    height: 21px;
  }
  .c-single01 .c-single01__cont ul li {
    padding-left: 19px;
  }
  .c-single01 .c-single01__cont ul li::before {
    top: 13px;
  }
  .c-single01 .c-single01__cont a:hover {
    text-decoration: none;
  }
  .c-single01 .c-single01__cont .c-single01__note {
    margin-top: 20px;
    font-size: 0.9375rem;
  }
  .c-single01 .c-single01__cont .c-single01__cont-box {
    padding: 28px 70px;
  }
  .c-single01 .c-single01__sns {
    margin-top: 80px;
  }
  .c-single01 .c-single01__sns-item-link {
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: opacity;
  }
  .c-single01 .c-single01__sns-item-link:hover {
    opacity: 0.6;
  }
}
@media all and (min-width: 1320px) {
  .c-single01 .c-single01__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 0 7%;
  }
  .c-single01 .c-single01__article {
    width: 75.7%;
    padding-bottom: 0;
  }
  .c-single01 .c-single01__side {
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: 17.3%;
  }
}

.u-clearfix {
  position: relative;
}
.u-clearfix::before {
  content: "";
  display: block;
  clear: both;
}

.u-font-noto {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  color: #0E101C;
  line-height: 1.6;
  letter-spacing: 0.05em;
  font-weight: 400;
  font-size: 0.9375rem;
}

.u-font-europa {
  font-family: europa, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.u-txt-uppercase {
  text-transform: uppercase;
}

.u-txt-center {
  text-align: center;
}

.u-txt-left {
  text-align: left;
}

.u-txt-right {
  text-align: right;
}

@media all and (min-width: 768px) {
  .u-view-small {
    display: none !important;
  }
}

@media all and (max-width: 767px) {
  .u-view-medium {
    display: none !important;
  }
}
@media all and (min-width: 1320px) {
  .u-view-medium {
    display: none !important;
  }
}

@media all and (max-width: 1319px) {
  .u-view-large {
    display: none !important;
  }
}

@media all and (min-width: 1320px) {
  .u-view-under-medium {
    display: none !important;
  }
}

@media all and (max-width: 767px) {
  .u-view-upper-medium {
    display: none !important;
  }
}

/*  color
---------------------------------------- */
.u-color-primary {
  color: #0077EF;
}

/*  404
================================================ */
.p-error__page404 {
  padding-top: 40px;
  padding-bottom: 100px;
}
@media all and (min-width: 768px) {
  .p-error__page404 {
    padding-top: 0;
    padding-bottom: 150px;
  }
}

/*  mv
---------------------------------------- */
.p-home-mv .p-home-mv__inner {
  display: grid;
  place-content: center;
  height: 100vh;
  padding-top: 50px;
}
.p-home-mv .p-home-mv__txt {
  width: 10.66%;
  margin: 0 auto;
}
.p-home-mv .p-home-mv__en {
  width: 100%;
  font-size: 4.375rem;
}
.p-home-mv .p-home-mv__en-in {
  letter-spacing: 0.08em;
  line-height: 1.1;
  white-space: nowrap;
}
.p-home-mv .p-home-mv__en-in:nth-child(1) {
  text-align: left;
}
.p-home-mv .p-home-mv__en-in:nth-child(2) {
  text-align: right;
}
@media all and (min-width: 768px) {
  .p-home-mv .p-home-mv__txt {
    width: min(55px, 6vh);
  }
  .p-home-mv .p-home-mv__en {
    font-size: 7.5rem;
  }
  .p-home-mv .p-home-mv__en-in:nth-child(1) {
    padding-left: 6.25%;
    text-align: left;
  }
  .p-home-mv .p-home-mv__en-in:nth-child(2) {
    padding-right: 6.25%;
    text-align: right;
  }
}
@media all and (min-width: 1320px) {
  .p-home-mv .p-home-mv__en {
    font-size: 9.375rem;
  }
}
@media all and (max-height: 700px) {
  .p-home-mv .p-home-mv__txt {
    width: 10%;
  }
}

/*  .p-home-vision
---------------------------------------- */
.p-home-vision {
  position: relative;
  padding: 90px 0 70px;
  z-index: 2;
}
.p-home-vision .p-home-vision__bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 210px;
  background: #1D1D21;
  z-index: -1;
}
.p-home-vision .p-home-vision__img {
  margin-left: -20px;
}
.p-home-vision .p-home-vision__txt-container {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  color: #F7F7F7;
}
.p-home-vision .p-home-vision__head {
  margin-bottom: 20px;
  font-size: 4.375rem;
  line-height: 1.2;
}
.p-home-vision .p-home-vision__txt {
  padding: 0 20px;
  font-size: 1.125rem;
  line-height: 2.2;
}
.p-home-vision .p-home-vision__btn {
  padding-left: 20px;
}
@media all and (min-width: 768px) {
  .p-home-vision {
    padding: 200px 0;
  }
  .p-home-vision .p-home-vision__bg {
    top: 400px;
  }
  .p-home-vision .p-home-vision__img {
    margin-left: 0;
  }
  .p-home-vision .p-home-vision__txt-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 180px;
  }
  .p-home-vision .p-home-vision__head {
    width: 50%;
    margin-bottom: 0;
    font-size: min(8.125rem, 6.7vw);
    line-height: 1.2;
  }
  .p-home-vision .p-home-vision__txt-wrap {
    max-width: 50%;
    justify-self: flex-end;
  }
  .p-home-vision .p-home-vision__txt {
    padding: 0;
    font-size: 1.375rem;
  }
  .p-home-vision .p-home-vision__btn {
    padding-left: 0;
  }
}

/*  p-home-service
---------------------------------------- */
.p-home-service {
  overflow: hidden;
  position: relative;
  padding: 70px 0;
  background: linear-gradient(to bottom, transparent 20%, #F7F7F7 20%);
  z-index: 1;
}
.p-home-service::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  transform: translateY(-100%);
  width: 100%;
  background: #F7F7F7;
  aspect-ratio: 1/1;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  z-index: -1;
}
.p-home-service .p-home-service__head {
  position: absolute;
  top: 0;
  left: -0.1em;
  z-index: -1;
}
.p-home-service .p-home-service__head-en {
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
  color: #F7F7F7;
  font-size: 4.6875rem;
  line-height: 1.1;
  white-space: nowrap;
}
.p-home-service .p-home-service__txt {
  margin-bottom: 60px;
}
.p-home-service .p-home-service__txt-in {
  display: block;
  font-size: 1rem;
}
.p-home-service .p-home-service__txt-in:nth-child(n+2) {
  margin-top: 10px;
}
.p-home-service .p-home-service__txt-in-large {
  color: #0077EF;
  font-size: 3.5rem;
  letter-spacing: 0.08em;
}
@media all and (min-width: 768px) {
  .p-home-service {
    padding: 200px 0 180px;
    background: linear-gradient(to bottom, transparent 70%, #F7F7F7 70%);
  }
  .p-home-service::before {
    top: 70%;
  }
  .p-home-service .p-home-service__head {
    left: -0.25em;
  }
  .p-home-service .p-home-service__head-en {
    font-size: 12.5rem;
  }
  .p-home-service .p-home-service__txt {
    margin-bottom: 150px;
  }
  .p-home-service .p-home-service__txt-in {
    font-size: 1.25rem;
  }
  .p-home-service .p-home-service__txt-in:nth-child(n+2) {
    margin-top: 0;
  }
  .p-home-service .p-home-service__txt-in--head {
    margin-bottom: 20px;
  }
  .p-home-service .p-home-service__txt-in-large {
    font-size: 5rem;
  }
}
@media all and (min-width: 1320px) {
  .p-home-service .p-home-service__txt {
    padding-left: 20%;
  }
}

.p-home-service-sec {
  position: relative;
}
.p-home-service-sec:nth-child(n+2) {
  margin-top: 50px;
}
.p-home-service-sec .p-home-service-sec__num {
  margin-bottom: 10px;
  color: #0077EF;
  font-size: 0.75rem;
  letter-spacing: 0.08em;
}
.p-home-service-sec .p-home-service-sec__img {
  margin-left: -20px;
}
.p-home-service-sec.p-home-service-sec--rev .p-home-service-sec__img {
  margin: 0 -20px 0 0;
}
.p-home-service-sec .p-home-service-sec__txt-container {
  margin-top: 30px;
}
.p-home-service-sec .p-home-service-sec__head {
  margin-bottom: 10px;
  color: #0077EF;
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.59;
}
.p-home-service-sec .p-home-service__sec-request {
  margin-top: 25px;
}
.p-home-service-sec .p-home-service__sec-request-head {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  padding: 6px 15px;
  color: #fff;
  font-size: 0.8125rem;
  line-height: 1.1;
  position: relative;
  z-index: 1;
  color: #fff;
}
.p-home-service-sec .p-home-service__sec-request-head::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}
.p-home-service-sec .p-home-service__sec-request-list {
  display: flex;
  flex-wrap: wrap;
  border: solid #DBDBDB;
  border-width: 0 0 1px 1px;
}
.p-home-service-sec .p-home-service__sec-request-item {
  width: 50%;
  padding: 25px 20px;
  border: solid #DBDBDB;
  border-width: 1px 1px 0 0;
}
.p-home-service-sec .p-home-service__sec-request-item-img {
  width: 40px;
  margin: 0 auto 20px;
}
@media all and (min-width: 768px) {
  .p-home-service-sec {
    display: grid;
    grid-template-columns: 48.5% auto;
    align-items: center;
    grid-template-areas: "img num" "img txt";
    gap: 0 8.5%;
  }
  .p-home-service-sec:nth-child(n+2) {
    margin-top: 150px;
  }
  .p-home-service-sec .p-home-service-sec__num {
    grid-area: num;
    align-self: end;
    margin-bottom: 30px;
    font-size: 0.875rem;
  }
  .p-home-service-sec .p-home-service-sec__img {
    grid-area: img;
    margin-left: -50px;
  }
  .p-home-service-sec.p-home-service-sec--rev {
    grid-template-columns: auto 48.5%;
    grid-template-areas: "num img" "txt img";
  }
  .p-home-service-sec.p-home-service-sec--rev .p-home-service-sec__img {
    margin: 0 -50px 0 0;
  }
  .p-home-service-sec.p-home-service-sec--rev .p-home-service-sec__num,
.p-home-service-sec.p-home-service-sec--rev .p-home-service-sec__txt-container {
    padding-left: 80px;
    max-width: 600px;
  }
  .p-home-service-sec .p-home-service-sec__txt-container {
    grid-area: txt;
    align-self: start;
    margin-top: 0;
    max-width: 520px;
  }
  .p-home-service-sec .p-home-service-sec__head {
    margin-bottom: 15px;
    font-size: 2.3125rem;
  }
  .p-home-service-sec .p-home-service__sec-request {
    margin-top: 60px;
  }
  .p-home-service-sec .p-home-service__sec-request-head {
    margin-bottom: 15px;
    padding: 8px 20px;
    font-size: 0.875rem;
    position: relative;
    z-index: 1;
    color: #fff;
  }
  .p-home-service-sec .p-home-service__sec-request-head::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #1D1D21;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7px), calc(100% - 7px) 100%, 0 100%);
  }
  .p-home-service-sec .p-home-service__sec-request-item {
    padding: 30px;
  }
  .p-home-service-sec .p-home-service__sec-request-item-img {
    width: 50px;
    margin: 0 auto 15px;
  }
}

/* p-home-blog
---------------------------------------- */
.p-home-blog {
  padding: 70px 0 35px;
}
.p-home-blog .p-home-blog__container {
  margin-bottom: 35px;
  padding: 0 20px;
}
.p-home-blog .p-home-blog__head {
  text-align: center;
  margin-bottom: 30px;
}
.p-home-blog .p-home-blog__head-company {
  margin-bottom: 20px;
  color: #0077EF;
  font-size: 2.5rem;
  line-height: 1.1;
}
.p-home-blog .p-home-blog__head-ttl {
  display: block;
  width: max-content;
  margin: 0 auto;
  padding: 10px 25px;
  font-size: 3.5rem;
  line-height: 1.1;
  position: relative;
  z-index: 1;
  color: #fff;
}
.p-home-blog .p-home-blog__head-ttl::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 12px), calc(100% - 12px) 100%, 0 100%);
}
.p-home-blog .p-home-blog__list-wrap {
  padding: 0 20px;
  padding-bottom: 30px;
}
.p-home-blog .js-blog-scrollbar.swiper-scrollbar {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 2px;
  background: #dbdbdb;
  opacity: 1;
}
.p-home-blog .js-blog-scrollbar.swiper-scrollbar .swiper-scrollbar-drag {
  color: #1D1D21;
}
@media all and (min-width: 768px) {
  .p-home-blog {
    padding: 180px 0 75px 6.25%;
  }
  .p-home-blog .p-home-blog__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 0 5%;
  }
  .p-home-blog .p-home-blog__container {
    width: 45%;
    margin-bottom: 0;
  }
  .p-home-blog .p-home-blog__list-wrap {
    position: relative;
    width: 50%;
    padding-top: 105px;
  }
  .p-home-blog .p-home-blog__control {
    position: absolute;
    top: 0;
    right: 6.25%;
    width: 300px;
    height: 60px;
  }
  .p-home-blog .p-home-blog__arw {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 120px;
    height: 60px;
    margin-left: auto;
    border: 1px solid #0E101C;
  }
  .p-home-blog .p-home-blog__arw::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #0E101C;
  }
  .p-home-blog .js-blog-slider__next,
.p-home-blog .js-blog-slider__prev {
    position: relative;
    width: 50%;
    height: 60px;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    margin: 0;
    opacity: 1;
  }
  .p-home-blog .js-blog-slider__next::after,
.p-home-blog .js-blog-slider__prev::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 16px;
    background: #0E101C;
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: background;
  }
  .p-home-blog .js-blog-slider__next:hover::after,
.p-home-blog .js-blog-slider__prev:hover::after {
    background: #0077EF;
  }
  .p-home-blog .js-blog-slider__prev::after {
    transform: translate(-50%, -50%) scale(-1, 1);
  }
  .p-home-blog .js-blog-scrollbar.swiper-scrollbar {
    top: 50%;
    transform: translateY(-50%);
    bottom: auto;
    left: 0;
    width: 120px;
  }
  .p-home-blog .p-home-blog__head {
    text-align: left;
    margin-bottom: 40px;
  }
  .p-home-blog .p-home-blog__head-company {
    margin-bottom: 30px;
    font-size: min(5rem, 4.4vw);
  }
  .p-home-blog .p-home-blog__head-ttl {
    margin: 0;
    padding: 15px 45px;
    font-size: min(7.5rem, 7vw);
    position: relative;
    z-index: 1;
    color: #fff;
  }
  .p-home-blog .p-home-blog__head-ttl::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #1D1D21;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
  }
}
@media all and (min-width: 1320px) {
  .p-home-blog .p-home-blog__inner {
    gap: 0 8.5%;
  }
  .p-home-blog .p-home-blog__container {
    width: 28%;
    padding: 0;
  }
  .p-home-blog .p-home-blog__list-wrap {
    width: 63.5%;
  }
}

/*  p-home-links
---------------------------------------- */
.p-home-links {
  padding: 35px 0 90px;
}
.p-home-links .p-home-links__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: solid #0E101C;
  border-width: 1px 0;
}
.p-home-links .p-home-links__list::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #0E101C;
}
.p-home-links .p-home-links__item {
  width: 50%;
}
.p-home-links .p-home-links__item-link {
  overflow: hidden;
  position: relative;
  display: grid;
  place-content: center;
  height: 200px;
  font-size: 1.125rem;
}
.p-home-links .p-home-links__item-link::before {
  content: "";
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 14px;
  height: 14px;
  background: #0077EF;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
@media all and (min-width: 768px) {
  .p-home-links {
    padding: 75px 0 230px;
  }
  .p-home-links .p-home-links__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: solid #0E101C;
    border-width: 1px 0;
  }
  .p-home-links .p-home-links__list::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #0E101C;
  }
  .p-home-links .p-home-links__item {
    width: 50%;
  }
  .p-home-links .p-home-links__item-link {
    position: relative;
    display: grid;
    place-content: center;
    height: 200px;
    font-size: 1.125rem;
    transition: 0.2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
    transition-property: background color;
  }
  .p-home-links .p-home-links__item-link:hover {
    background: #1D1D21;
    color: #0077EF;
  }
}

.main-content {
  display: flex;
  flex-wrap: wrap;
}
.main-content .content {
  height: 1000px;
}

/*  p-about-vision
---------------------------------------- */
.p-about-vision {
  position: relative;
  padding-bottom: 70px;
  z-index: 1;
}
.p-about-vision::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #F7F7F7;
  aspect-ratio: 1/1;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  z-index: -1;
}
.p-about-vision .p-about-vision__img {
  margin-left: -20px;
}
.p-about-vision .p-about-vision__head {
  padding-top: 60px;
  font-size: 3.5rem;
  line-height: 1.36;
  letter-spacing: 0.08em;
}
.p-about-vision .p-about-vision__txt-area {
  padding: 30px 20px 0;
}
.p-about-vision .p-about-vision__lead {
  margin-bottom: 25px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.59;
}
.p-about-vision .p-about-vision__txt {
  line-height: 2;
}
.p-about-vision .p-about-vision__txt + .p-about-vision__txt {
  margin-top: 20px;
}
@media all and (min-width: 1320px) {
  .p-about-vision {
    padding-bottom: 150px;
  }
  .p-about-vision::before {
    width: 66.1%;
  }
  .p-about-vision .p-about-vision__inner {
    display: grid;
    grid-template-columns: auto 68.7%;
    grid-template-areas: "head img" ". txt";
    gap: 70px 7.8%;
  }
  .p-about-vision .p-about-vision__img {
    grid-area: img;
    margin-left: 0;
  }
  .p-about-vision .p-about-vision__head {
    grid-area: head;
    padding-top: 0;
    font-size: 5rem;
    line-height: 1.2;
  }
  .p-about-vision .p-about-vision__txt-area {
    grid-area: txt;
    padding: 0;
  }
  .p-about-vision .p-about-vision__lead {
    margin-bottom: 50px;
    font-size: 1.875rem;
  }
  .p-about-vision .p-about-vision__txt + .p-about-vision__txt {
    margin-top: 25px;
  }
}

/*  p-about-mission
---------------------------------------- */
.p-about-mission {
  position: relative;
  padding: 70px 20px;
}
.p-about-mission::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: #1D1D21;
  z-index: -1;
}
.p-about-mission .p-about-mission__head {
  margin-bottom: 30px;
  text-align: center;
  color: #F7F7F7;
  font-size: 3.5rem;
  letter-spacing: 0.08em;
  line-height: 1.1;
}
.p-about-mission .p-about-mission__head-small {
  font-size: 2.5rem;
}
.p-about-mission .p-about-mission__txt {
  color: #F7F7F7;
  font-size: 1.125rem;
  letter-spacing: 0.1em;
  line-height: 2.2;
}
@media all and (min-width: 768px) {
  .p-about-mission {
    padding: 150px 0;
  }
  .p-about-mission::before {
    left: 60px;
  }
  .p-about-mission .p-about-mission__head {
    margin-bottom: 50px;
    text-align: center;
    font-size: 5rem;
  }
  .p-about-mission .p-about-mission__head-small {
    font-size: 3.125rem;
  }
  .p-about-mission .p-about-mission__txt {
    text-align: center;
    font-size: 1.375rem;
  }
}

/*  p-about-value
---------------------------------------- */
.p-about-value {
  padding: 70px 0;
}
.p-about-value .p-about-value__head {
  margin-bottom: 20px;
  font-size: 3.5rem;
  line-height: 1.2;
  letter-spacing: 0.08em;
}
.p-about-value .p-about-value__list {
  border-top: 1px solid #DBDBDB;
}
.p-about-value .p-about-value__item {
  padding: 35px 20px;
  border-bottom: 1px solid #DBDBDB;
}
.p-about-value .p-about-value__item-num {
  font-size: 0.75rem;
  line-height: 1.1;
  letter-spacing: 0.08em;
}
.p-about-value .p-about-value__item-head {
  margin: 25px 0;
}
.p-about-value .p-about-value__item-head-en {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: 0.08em;
}
.p-about-value .p-about-value__item-head-ja {
  display: inline-block;
  margin-top: 10px;
  padding: 3px 15px;
  font-size: 1rem;
  position: relative;
  z-index: 1;
  color: #fff;
}
.p-about-value .p-about-value__item-head-ja::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
}
.p-about-value .p-about-value__item-img {
  width: 80px;
  margin: 10px 0 0 auto;
}
@media all and (min-width: 768px) {
  .p-about-value {
    padding: 150px 0 180px;
  }
  .p-about-value .p-about-value__head {
    font-size: 5rem;
  }
  .p-about-value .p-about-value__item {
    display: grid;
    grid-template-columns: auto 1fr 100px;
    grid-template-areas: "num head img" ". txt img";
    padding: 65px 20px;
  }
  .p-about-value .p-about-value__item-num {
    grid-area: num;
    padding: 15px 35px 0 0;
    font-size: 0.875rem;
  }
  .p-about-value .p-about-value__item-head {
    grid-area: head;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin: 0 0 25px;
  }
  .p-about-value .p-about-value__item-head-en {
    font-size: 2.1875rem;
  }
  .p-about-value .p-about-value__item-head-ja {
    margin-top: 0;
    padding: 2px 17px;
  }
  .p-about-value .p-about-value__item-txt {
    grid-area: txt;
  }
  .p-about-value .p-about-value__item-img {
    grid-area: img;
    width: 110px;
    margin: 0;
  }
}
@media all and (min-width: 1320px) {
  .p-about-value .p-about-value__inner {
    display: flex;
    gap: 0 7.8%;
  }
  .p-about-value .p-about-value__head {
    width: 23.5%;
  }
  .p-about-value .p-about-value__body {
    width: 68.7%;
  }
}

/*  p-about-message
---------------------------------------- */
.p-about-message {
  position: relative;
  padding: 30px 0 70px;
  z-index: 1;
}
.p-about-message::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 90px;
  background: #F7F7F7;
  z-index: -1;
}
.p-about-message .p-about-message__head {
  margin-bottom: 30px;
  font-size: 3.5rem;
  letter-spacing: 0.08em;
  line-height: 1.1;
}
.p-about-message .p-about-message__body-img {
  position: relative;
}
.p-about-message .p-about-message__body-img-in {
  border: 1px solid #0E101C;
}
.p-about-message .p-about-message__body-img-name-area {
  position: absolute;
  left: 0;
  bottom: -25px;
}
.p-about-message .p-about-message__body-img-name-position {
  display: block;
  width: max-content;
  margin-bottom: 2px;
  padding: 5px 15px;
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.p-about-message .p-about-message__body-img-name-position::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1D1D21;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
}
.p-about-message .p-about-message__body-img-name {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px 10px;
  padding: 7px 30px;
  background: #1D1D21;
  letter-spacing: 0.08em;
  font-weight: 700;
}
.p-about-message .p-about-message__body-img-name-ja {
  font-size: 1.375rem;
  color: #F7F7F7;
}
.p-about-message .p-about-message__body-img-name-en {
  color: #616165;
  font-size: 0.625rem;
  line-height: 1.1;
}
.p-about-message .p-about-message__body-txt-area {
  padding-top: 55px;
}
.p-about-message .p-about-message__body-txt-lead {
  margin-bottom: 25px;
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.6;
  font-weight: 700;
}
.p-about-message .p-about-message__body-txt-lead-small {
  display: inline-block;
  font-size: 1.375rem;
}
.p-about-message .p-about-message__body-txt {
  line-height: 1.8;
}
@media all and (min-width: 768px) {
  .p-about-message {
    padding: 120px 0 150px;
  }
  .p-about-message::before {
    top: 160px;
  }
  .p-about-message .p-about-message__head {
    margin-bottom: 80px;
    font-size: 5rem;
  }
  .p-about-message .p-about-message__body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 7%;
  }
  .p-about-message .p-about-message__body-img {
    position: relative;
    align-self: flex-start;
    width: 43%;
  }
  .p-about-message .p-about-message__body-img-name-area {
    bottom: 30px;
  }
  .p-about-message .p-about-message__body-img-name-position {
    margin-bottom: 4px;
    padding: 2px 18px;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 1rem;
  }
  .p-about-message .p-about-message__body-img-name-position::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #1D1D21;
    z-index: -1;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 6px), calc(100% - 6px) 100%, 0 100%);
  }
  .p-about-message .p-about-message__body-img-name {
    gap: 5px 15px;
    padding: 20px 25px;
  }
  .p-about-message .p-about-message__body-img-name-ja {
    font-size: 1.625rem;
  }
  .p-about-message .p-about-message__body-img-name-en {
    font-size: 0.75rem;
  }
  .p-about-message .p-about-message__body-txt-area {
    width: 50%;
    padding-top: 0;
  }
  .p-about-message .p-about-message__body-txt-lead {
    margin-bottom: 40px;
    font-size: 2.3125rem;
  }
}

/* p-about-service
---------------------------------------- */
.p-about-service {
  position: relative;
  padding: 70px 0 110px;
}
.p-about-service .p-about-service__bg {
  position: absolute;
  top: -10px;
  left: -20px;
  transform: translateY(-100%) rotate(90deg);
  transform-origin: left bottom;
  font-size: 4.6875rem;
  color: #F7F7F7;
  line-height: 1.1;
  z-index: -1;
}
.p-about-service .p-about-service__head {
  margin-bottom: 45px;
  font-size: 3.5rem;
  letter-spacing: 0.08em;
  line-height: 1.2;
}
.p-about-service .p-about-service__head-name {
  font-size: 2.1875rem;
}
.p-about-service .p-about-service__sec:nth-child(n+2) {
  margin-top: 50px;
}
.p-about-service .p-about-service__sec-example {
  margin-top: 25px;
}
.p-about-service .p-about-service__sec-example-head {
  margin-bottom: -1px;
  padding: 10px 20px;
  border: 1px solid #DBDBDB;
  text-align: center;
  font-size: 1.125rem;
}
.p-about-service .p-about-service__sec-example-list {
  display: flex;
  flex-wrap: wrap;
  border: solid #DBDBDB;
  border-width: 1px 0 0 1px;
}
.p-about-service .p-about-service__sec-example-item {
  width: 50%;
  padding: 20px 25px;
  border: solid #DBDBDB;
  border-width: 0 1px 1px 0;
}
.p-about-service .p-about-service__sec-example-img {
  width: 40px;
  margin: 0 auto;
}
.p-about-service .p-about-service__sec-example-txt {
  margin-top: 15px;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
}
.p-about-service .p-about-service__sec-example-txt-small {
  font-weight: 500;
}
@media all and (min-width: 768px) {
  .p-about-service {
    padding: 180px 0;
  }
  .p-about-service .p-about-service__bg {
    top: -5px;
    left: -30px;
    font-size: 12.5rem;
  }
  .p-about-service .p-about-service__head {
    margin-bottom: 80px;
    font-size: 5rem;
  }
  .p-about-service .p-about-service__head-name {
    font-size: 2.5rem;
  }
  .p-about-service .p-about-service__sec:nth-child(n+2) {
    margin-top: 100px;
  }
  .p-about-service .p-about-service__sec-example {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    border: solid #DBDBDB;
    border-width: 1px 0 0 1px;
  }
  .p-about-service .p-about-service__sec-example-head {
    display: grid;
    place-content: center;
    width: 25%;
    margin-bottom: 0;
    font-size: 1.125rem;
    border-width: 0 1px 1px 0;
  }
  .p-about-service .p-about-service__sec-example-list {
    width: 75%;
    border: 0;
  }
  .p-about-service .p-about-service__sec-example-item {
    width: 33.333%;
    padding: 40px 25px;
  }
  .p-about-service .p-about-service__sec-example-img {
    width: 50px;
  }
  .p-about-service .p-about-service__sec-example-txt {
    font-size: 0.9375rem;
  }
}

/*  p-about-company
---------------------------------------- */
.p-about-company {
  position: relative;
  margin-top: -60px;
  padding: 90px 0 120px;
  background: #F7F7F7;
}
.p-about-company::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  aspect-ratio: 1/1;
  background: #F7F7F7;
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
  z-index: -1;
}
.p-about-company .p-about-company__box {
  position: relative;
  padding: 50px 20px;
  z-index: 1;
}
.p-about-company .p-about-company__box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: -1;
}
.p-about-company .p-about-company__head {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  line-height: 1.1;
  letter-spacing: 0.08em;
}
@media all and (min-width: 768px) {
  .p-about-company {
    margin-top: -110px;
    padding: 145px 0 300px;
  }
  .p-about-company .p-about-company__head {
    font-size: 5rem;
  }
}
@media all and (min-width: 1320px) {
  .p-about-company .p-about-company__box {
    padding: 120px 15% 150px;
  }
  .p-about-company .p-about-company__box::before {
    left: -320px;
    right: -200px;
  }
}

/*  p-policy-privacy
---------------------------------------- */
.p-policy-privacy {
  padding-bottom: 120px;
  line-height: 1.8;
}
.p-policy-privacy .p-policy-privacy__lead {
  border: 1px solid #0E101C;
  padding: 20px 25px;
}
.p-policy-privacy .p-policy-privacy__list {
  margin-top: 50px;
}
.p-policy-privacy .p-policy-privacy__item:nth-child(n+2) {
  margin-top: 50px;
}
.p-policy-privacy .p-policy-privacy__item-head {
  margin-bottom: 20px;
  font-size: 1.1875rem;
}
.p-policy-privacy .p-policy-privacy__item-head-num {
  display: inline-block;
  margin-right: 10px;
  color: #0077EF;
  font-size: 1.25rem;
  line-height: 1.6;
}
.p-policy-privacy .p-policy-privacy__item-txt:nth-child(n+2) {
  margin-top: 1em;
}
.p-policy-privacy .p-policy-privacy__item-num {
  margin-top: 15px;
}
.p-policy-privacy .p-policy-privacy__item-num-item {
  padding-left: 1.6em;
}
.p-policy-privacy .p-policy-privacy__item-num-item:nth-child(n+2) {
  margin-top: 15px;
}
.p-policy-privacy .p-policy-privacy__item-num-item * {
  text-indent: 0;
  padding-left: 0;
}
.p-policy-privacy .p-policy-privacy__item-num-item .p-policy-privacy__item-num-item-txt {
  text-indent: -1.6em;
}
.p-policy-privacy .p-policy-privacy__item-num-item .p-policy-privacy__item-sub-item {
  padding-left: 17px;
  text-indent: -17px;
}
.p-policy-privacy .p-policy-privacy__item-num-item .p-policy-privacy__item-note-item {
  padding-left: 1.2em;
  text-indent: -1.2em;
}
.p-policy-privacy .p-policy-privacy__item-sub {
  margin-top: 10px;
  font-size: 0.875rem;
}
.p-policy-privacy .p-policy-privacy__item-sub-item:nth-child(n+2) {
  margin-top: 10px;
}
.p-policy-privacy .p-policy-privacy__item-note {
  margin-top: 15px;
}
.p-policy-privacy .p-policy-privacy__item-note-list {
  margin-top: 5px;
  font-size: 0.875rem;
}
.p-policy-privacy .p-policy-privacy__item-note-item:nth-child(n+2) {
  margin-top: 10px;
}
@media all and (min-width: 768px) {
  .p-policy-privacy {
    padding-bottom: 290px;
  }
  .p-policy-privacy .p-policy-privacy__lead {
    padding: 40px 45px;
  }
  .p-policy-privacy .p-policy-privacy__list {
    margin-top: 100px;
  }
  .p-policy-privacy .p-policy-privacy__item:nth-child(n+2) {
    margin-top: 80px;
  }
  .p-policy-privacy .p-policy-privacy__item-head {
    font-size: 1.25rem;
  }
  .p-policy-privacy .p-policy-privacy__item-head-num {
    margin-right: 13px;
    font-size: 1.375rem;
  }
  .p-policy-privacy .p-policy-privacy__item-num {
    margin-top: 25px;
  }
  .p-policy-privacy .p-policy-privacy__item-num-item:nth-child(n+2) {
    margin-top: 18px;
  }
  .p-policy-privacy .p-policy-privacy__item-num-item .p-policy-privacy__item-sub-item {
    padding-left: 17px;
    text-indent: -17px;
  }
  .p-policy-privacy .p-policy-privacy__item-sub {
    font-size: 0.9375rem;
  }
  .p-policy-privacy .p-policy-privacy__item-sub-item:nth-child(n+2) {
    margin-top: 5px;
  }
  .p-policy-privacy .p-policy-privacy__item-note-list {
    margin-top: 10px;
    font-size: 0.9375rem;
  }
  .p-policy-privacy .p-policy-privacy__item-note-item:nth-child(n+2) {
    margin-top: 5px;
  }
}

.p-contact-form {
  padding-bottom: 124px;
}
.p-contact-form .p-contact-form__required {
  margin-bottom: 10px;
}
.p-contact-form .p-contact-form__box {
  margin: 0 -20px;
  padding: 50px 20px;
  background: #F7F7F7;
}
@media all and (min-width: 768px) {
  .p-contact-form {
    padding-bottom: 290px;
  }
  .p-contact-form .p-contact-form__required {
    margin-bottom: 20px;
  }
  .p-contact-form .p-contact-form__box {
    margin: 0;
    padding: 80px 90px;
  }
}

/*  blog
---------------------------------------- */
.p-blog-archive {
  padding-bottom: 120px;
}
.p-blog-archive .p-blog-archive__list {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
.p-blog-archive .p-blog-archive__item {
  width: 100%;
}
@media all and (min-width: 768px) {
  .p-blog-archive {
    padding-bottom: 300px;
  }
  .p-blog-archive .p-blog-archive__list {
    gap: 50px 10px;
  }
  .p-blog-archive .p-blog-archive__item {
    width: calc(33.33% - 6.6666666667px);
  }
}
@media all and (min-width: 1320px) {
  .p-blog-archive .p-blog-archive__inner {
    display: grid;
    grid-template-columns: 75.6% auto;
    grid-template-rows: auto 1fr;
    grid-template-areas: "archive category" "archive keyword";
    gap: 45px 7%;
  }
  .p-blog-archive .p-blog-archive__category {
    align-self: start;
    grid-area: category;
  }
  .p-blog-archive .p-blog-archive__keyword {
    align-self: start;
    grid-area: keyword;
  }
  .p-blog-archive .p-blog-archive__body {
    grid-area: archive;
  }
  .p-blog-archive .p-blog-archive__list {
    gap: 50px 40px;
  }
  .p-blog-archive .p-blog-archive__item {
    width: calc(33.33% - 26.6666666667px);
  }
}