@use '../../fundation/index' as *;

/*  404
================================================ */
.p-error__page404{
	padding-top: 40px;
	padding-bottom: 100px;
	@include mq (medium){
		padding-top: 0;
		padding-bottom: 150px;
	}
}