@use '../../fundation/index' as *;
/*  list
---------------------------------------- */
.c-list01{
	.c-list01__item{
		position: relative;
		padding-left: 17px;
		&::before{
			content: '';
			position: absolute;
			left: 0;
			top: 13px;
			width: 6px;
			height: 3px;
			background: $clr_primary;
		}
	}
	@include mq (medium) {
		.c-list01__item{
			padding-left: 19px;
			&::before{
				top: 12px;
			}
		}
	}
}