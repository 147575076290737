@use '../../fundation/index' as *;

/*  c-form01
---------------------------------------- */
.c-form01{
	>*{
		font-size: min(16px,font-size(16));
	}
	.c-form01__in{
		&:nth-child(n+2){
			margin-top: 60px;
		}
		dt,
		&-head{
			margin-bottom: 15px;
			font-size: font-size(16);
			font-weight: 700;
			line-height: 1.6;
		}
	}
	.c-form01__in-privacy{
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.c-form01__in-privacy-link{
		color: $clr_txt_gray;
		font-size: font-size(14);
		font-weight: 500;
	}
	input,textarea {
		appearance: none;
		border: 0;
		outline: 0;
		border-radius: 0;
		border-bottom: 1px solid $clr_border;
		background: transparent;
	}
	input[type='text'],
	input[type="email"],
	textarea{
		box-sizing: border-box;
		width: 100%;
		padding: 15px 0;
	}
	textarea{
		height: 200px;
		padding: 15px 20px;
		border: 1px solid $clr_border;
	}
	input::placeholder {
		color: $clr_txt_gray;
	}
	input:-ms-input-placeholder {
		color: $clr_txt_gray;
	}
	input::-ms-input-placeholder {
		color: $clr_txt_gray;
	}
	button{
		border: none;
		outline: none;
		appearance: none;
		cursor: pointer;
	}
	label{
		cursor: pointer;
	}
	input[type='checkbox']{
		display: none;
		+span{
			position: relative;
			display: inline-block;
			padding-left: 37px;
			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 22px;
				height: 22px;
				border: 1px solid $clr_default;
			}
		}
	}
	input[type='checkbox']:checked{
		+span{
			&::after{
				content: '';
				position: absolute;
				top: 50%;
				left: 6px;
				transform: translateY(-50%);
				width: 12px;
				height: 12px;
				background: $clr_primary;
			}
		}
	}
	@include mq (medium) {
		.c-form01__in{
			dt{
				margin-bottom: 18px;
			}
		}
		.c-form01__in-privacy{
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		.c-form01__in-privacy-link a{
			&:hover{
				text-decoration: none;
			}
		}
	}
}

/*  .c-form01__btn
---------------------------------------- */
.c-form01__btn{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin-top: 50px;
	button{
		width: min(100%,270px);
		padding: 20px;
		border: 1px solid $clr_border;
		background: #fff;
		text-align: center;
		font-size: font-size(15);
		font-weight: 700;
		color: $clr_default;
		&[value="back"]{
			border-color: $clr_txt_gray;
			background: $clr_txt_gray;
			color: $clr_txt_white;
		}
	}
	@include mq (medium) {
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 20px;
		margin-top: 80px;
		button{
			width: min(100%,350px);
			padding: 25px;
			font-size: font-size(16);
			@include trans-normal(color border);
			&:hover{
				border-color: $clr_primary;
				color: $clr_primary;
				&[value="back"]{
					border-color: $clr_txt_gray;
					color: $clr_txt_gray;
					background: transparent;

				}
			}
		}
	}
}