@use '../../fundation/index' as *;
/*  card
---------------------------------------- */
.c-card01{
	.c-card01__link{
		display: block;
	}
	.c-card01__img{
		overflow: hidden;
		border: 1px solid $clr_border_secondary;
	}
	.c-card01__txt-area{
		padding-top: 20px;
	}
	.c-card01__meta{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px 12px;
		margin-bottom: 10px;
	}
	.c-card01__cats{
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.c-card01__cat{
		display: inline-block;
		padding: 4px 12px;
		background: $clr_bg;
		color: #fff;
		font-size: font-size(12);
	}
	.c-card01__date{
		color: $clr_txt_gray;
		font-size: font-size(13);
	}
	.c-card01__ttl{
		font-size: font-size(18);
		font-weight: 700;
		line-height: 1.8;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.c-card01__tags{
		display: flex;
		flex-wrap: wrap;
		gap: 5px 10px;
		margin-top: 15px;
	}
	.c-card01__tags{
		color: $clr_txt_gray;
		font-size: font-size(12);
		line-height: 1.2;
	}
	@include mq (medium) {
		width: 350px;
		.c-card01__img{
			img{
				@include trans-normal(transform);
			}
		}
		.c-card01__txt-area{
			padding-top: 25px;
		}
		.c-card01__ttl{
			@include trans-normal(color);
		}
		.c-card01__tags{
			gap: 5px 15px;
			margin-top: 20px;
		}
		.c-card01__tags{
			font-size: font-size(14);
		}
		.c-card01__link:hover{
			.c-card01__img img{
				transform: scale(1.1) rotate(3deg);
			}
			.c-card01__ttl{
				color: $clr_primary;
			}
		}
	}
	@include mq (large) {
		.c-card01__cat{
			font-size: font-size(15);
		}
		.c-card01__ttl{
			font-size: font-size(20);
			@include trans-normal(color);
		}
	}
}