@use '../../fundation/index' as *;

/*  mv
---------------------------------------- */
.p-home-mv{
	.p-home-mv__inner{
		display: grid;
		place-content: center;
		height: 100vh;
		padding-top: 50px;
	}
	.p-home-mv__txt{
		width: 10.66%;
		margin: 0 auto;
	}
	.p-home-mv__en{
		width: 100%;
		font-size: font-size(70);
	}
	.p-home-mv__en-in{
		letter-spacing: .08em;
		line-height: 1.1;
		white-space: nowrap;
		&:nth-child(1){
			text-align: left;
		}
		&:nth-child(2){
			text-align: right;
		}
	}
	@include mq (medium) {
		.p-home-mv__txt{
			width: min(55px,6vh);
		}
		.p-home-mv__en{
			font-size: font-size(120);
		}
		.p-home-mv__en-in{
			&:nth-child(1){
				padding-left: 6.25%;
				text-align: left;
			}
			&:nth-child(2){
				padding-right: 6.25%;
				text-align: right;
			}
		}
	}
	@include mq (large) {
		.p-home-mv__en{
			font-size: font-size(150);
		}
	}
	@media all and(max-height: 700px){
		.p-home-mv__txt{
			width: 10%;
		}
	}
}

/*  .p-home-vision
---------------------------------------- */
.p-home-vision{
	position: relative;
	padding: 90px 0 70px;
	z-index: 2;
	.p-home-vision__bg{
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 210px;
		background: $clr_bg;
		z-index: -1;
	}
	.p-home-vision__img{
		margin-left: -20px;
	}
	.p-home-vision__txt-container{
		display: flex;
		flex-direction: column;
		padding-top: 60px;
		color: $clr_txt_white;
	}
	.p-home-vision__head{
		margin-bottom: 20px;
		font-size: font-size(70);
		line-height: 1.2;
	}
	.p-home-vision__txt{
		padding: 0 20px;
		font-size: font-size(18);
		line-height: 2.2;
	}
	.p-home-vision__btn{
		padding-left: 20px;
	}
	@include mq (medium) {
		padding: 200px 0;
		.p-home-vision__bg{
			top: 400px;
		}
		.p-home-vision__img{
			margin-left: 0;
		}
		.p-home-vision__txt-container{
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
			padding-top: 180px;
		}
		.p-home-vision__head{
			width: 50%;
			margin-bottom: 0;
			font-size: min(font-size(130),6.7vw);
			line-height: 1.2;
		}
		.p-home-vision__txt-wrap{
			max-width: 50%;
			justify-self: flex-end;
		}
		.p-home-vision__txt{
			padding: 0;
			font-size: font-size(22);
		}
		.p-home-vision__btn{
			padding-left: 0;
		}
	}
}

/*  p-home-service
---------------------------------------- */
.p-home-service{
	overflow: hidden;
	position: relative;
	padding: 70px 0;
	background: linear-gradient(to bottom, transparent 20%, $clr_bg_secondary 20%);
	z-index: 1;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 20%;
		transform: translateY(-100%);
		width: 100%;
		background: $clr_bg_secondary;
		aspect-ratio: 1/1;
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
		z-index: -1;
	}
	.p-home-service__head{
		position: absolute;
		top: 0;
		left: -.1em;
		z-index: -1;
	}
	.p-home-service__head-en{
		transform: rotate(90deg) translateY(-100%);
		transform-origin: left top;
		color: $clr_txt_white;
		font-size: font-size(75);
		line-height: 1.1;
		white-space: nowrap;
	}
	.p-home-service__txt{
		margin-bottom: 60px;
	}
	.p-home-service__txt-in{
		display: block;
		font-size: font-size(16);
		&:nth-child(n+2){
			margin-top: 10px;
		}
	}
	.p-home-service__txt-in-large{
		color: $clr_primary;
		font-size: font-size(56);
		letter-spacing: .08em;
	}
	@include mq (medium){
		padding: 200px 0 180px;
		background: linear-gradient(to bottom, transparent 70%, $clr_bg_secondary 70%);
		&::before{
			top: 70%;
		}
		.p-home-service__head{
			left: -.25em;
		}
		.p-home-service__head-en{
			font-size: font-size(200);
		}
		.p-home-service__txt{
			margin-bottom: 150px;
		}
		.p-home-service__txt-in{
			font-size: font-size(20);
			&:nth-child(n+2){
				margin-top: 0;
			}
		}
		.p-home-service__txt-in--head{
			margin-bottom: 20px;
		}
		.p-home-service__txt-in-large{
			font-size: font-size(80);
		}
	}
	@include mq (large) {
		.p-home-service__txt{
			padding-left: 20%;
		}
	}
}

.p-home-service-sec{
	position: relative;
	&:nth-child(n+2){
		margin-top: 50px;
	}
	.p-home-service-sec__num{
		margin-bottom: 10px;
		color: $clr_primary;
		font-size: font-size(12);
		letter-spacing: .08em;
	}
	.p-home-service-sec__img{
		margin-left: -20px;
	}
	&.p-home-service-sec--rev{
		.p-home-service-sec__img{
			margin: 0 -20px 0 0;
		}
	}
	.p-home-service-sec__txt-container{
		margin-top: 30px;
	}
	.p-home-service-sec__head{
		margin-bottom: 10px;
		color: $clr_primary;
		font-size: font-size(32);
		letter-spacing: .1em;
		line-height: 1.59;
	}
	.p-home-service__sec-request{
		margin-top: 25px;
	}
	.p-home-service__sec-request-head{
		position: relative;
		display: inline-block;
		margin-bottom: 12px;
		padding: 6px 15px;
		color: #fff;
		font-size: font-size(13);
		line-height: 1.1;
		@include tagDesign;
	}
	.p-home-service__sec-request-list{
		display: flex;
		flex-wrap: wrap;
		border: solid $clr_border_secondary;
		border-width: 0 0 1px 1px;
	}
	.p-home-service__sec-request-item{
		width: 50%;
		padding: 25px 20px;
		border: solid $clr_border_secondary;
		border-width: 1px 1px 0 0;
	}
	.p-home-service__sec-request-item-img{
		width: 40px;
		margin: 0 auto 20px;
	}
	@include mq (medium) {
		display: grid;
		grid-template-columns: 48.5% auto;
		align-items: center;
		grid-template-areas:
		'img num'
		'img txt';
		gap: 0 8.5%;
		&:nth-child(n+2){
			margin-top: 150px;
		}
		.p-home-service-sec__num{
			grid-area: num;
			align-self: end;
			margin-bottom: 30px;
			font-size: font-size(14);
		}
		.p-home-service-sec__img{
			grid-area: img;
			margin-left: -50px;
		}
		&.p-home-service-sec--rev{
			grid-template-columns: auto 48.5%;
			grid-template-areas:
			'num img'
			'txt img';
			.p-home-service-sec__img{
				margin: 0 -50px 0 0;
			}
			.p-home-service-sec__num,
			.p-home-service-sec__txt-container{
				padding-left: 80px;
				max-width: 600px;

			}
		}
		.p-home-service-sec__txt-container{
			grid-area: txt;
			align-self: start;
			margin-top: 0;
			max-width: 520px;
		}
		.p-home-service-sec__head{
			margin-bottom: 15px;
			font-size: font-size(37);
		}
		.p-home-service__sec-request{
			margin-top: 60px;
		}
		.p-home-service__sec-request-head{
			margin-bottom: 15px;
			padding: 8px 20px;
			font-size: font-size(14);
			@include tagDesign(7);
		}
		.p-home-service__sec-request-item{
			padding: 30px;
		}
		.p-home-service__sec-request-item-img{
			width: 50px;
			margin: 0 auto 15px;
		}
	}
}

/* p-home-blog
---------------------------------------- */
.p-home-blog{
	padding: 70px 0 35px;
	.p-home-blog__container{
		margin-bottom: 35px;
		padding: 0 20px;
	}
	.p-home-blog__head{
		text-align: center;
		margin-bottom: 30px;
	}
	.p-home-blog__head-company{
		margin-bottom: 20px;
		color: $clr_primary;
		font-size: font-size(40);
		line-height: 1.1;
	}
	.p-home-blog__head-ttl{
		display: block;
		width: max-content;
		margin: 0 auto;
		padding: 10px 25px;
		font-size: font-size(56);
		line-height: 1.1;
		@include tagDesign(12);
	}
	.p-home-blog__list-wrap{
		padding: 0 20px;
		padding-bottom: 30px;
	}
	.js-blog-scrollbar.swiper-scrollbar{
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 80px;
		height: 2px;
		background: #dbdbdb;
		opacity: 1;
		.swiper-scrollbar-drag{
			color: #1D1D21;
		}
	}
	@include mq (medium) {
		padding: 180px 0 75px 6.25%;
		.p-home-blog__inner{
			display: flex;
			flex-wrap: wrap;
			gap: 0 5%;
		}
		.p-home-blog__container{
			width: 45%;
			margin-bottom: 0;
		}
		.p-home-blog__list-wrap{
			position: relative;
			width: calc(100% - 45% - 5%);
			padding-top: 105px;
		}
		.p-home-blog__control{
			position: absolute;
			top: 0;
			right: 6.25%;
			width: 300px;
			height: 60px;
		}
		.p-home-blog__arw{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			width: 120px;
			height: 60px;
			margin-left: auto;
			border: 1px solid $clr_border;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 0;
				bottom: 0;
				width: 1px;
				background: $clr_border;
			}
		}
		.js-blog-slider__next,
		.js-blog-slider__prev{
			position: relative;
			width: 50%;
			height: 60px;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			transform: translate(0,0);
			margin: 0;
			opacity: 1;
			&::after{
				content: '';
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
				width: 8px;
				height: 16px;
				background: $clr_default;
				clip-path: polygon(0 0, 100% 50%, 0 100%);
				@include trans-normal(background);
			}
			&:hover{
				&::after{
					background: $clr_primary;
				}
			}
		}
		.js-blog-slider__prev{
			&::after{
				transform: translate(-50%,-50%) scale(-1,1);
			}
		}
		.js-blog-scrollbar.swiper-scrollbar{
			top: 50%;
			transform: translateY(-50%);
			bottom: auto;
			left: 0;
			width: 120px;
		}
		.p-home-blog__head{
			text-align: left;
			margin-bottom: 40px;
		}
		.p-home-blog__head-company{
			margin-bottom: 30px;
			font-size: min(font-size(80),4.4vw);
		}
		.p-home-blog__head-ttl{
			margin: 0;
			padding: 15px 45px;
			font-size: min(font-size(120),7vw);
			@include tagDesign(15);
		}
	}
	@include mq (large) {
		.p-home-blog__inner{
			gap: 0 8.5%;
		}
		.p-home-blog__container{
			width: 28%;
			padding: 0;
		}
		.p-home-blog__list-wrap{
			width: calc(100% - 28% - 8.5%);
		}
	}
}

/*  p-home-links
---------------------------------------- */
.p-home-links{
	padding: 35px 0 90px;
	.p-home-links__list{
		position: relative;
		display: flex;
		flex-wrap: wrap;
		border: solid $clr_border;
		border-width: 1px 0;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 0;
			bottom: 0;
			width: 1px;
			background: $clr_border;
		}
	}
	.p-home-links__item{
		width: 50%;
	}
	.p-home-links__item-link{
		overflow: hidden;
		position: relative;
		display: grid;
		place-content: center;
		height: 200px;
		font-size: font-size(18);
		&::before{
			content: '';
			position: absolute;
			right: -1px;
			bottom: -1px;
			width: 14px;
			height: 14px;
			background: $clr_primary;
			clip-path: polygon(100% 0, 100% 100%, 0 100%);
		}
	}
	@include mq (medium) {
		padding: 75px 0 230px;
		.p-home-links__list{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			border: solid $clr_border;
			border-width: 1px 0;
			&::before{
				content: '';
				position: absolute;
				left: 50%;
				top: 0;
				bottom: 0;
				width: 1px;
				background: $clr_border;
			}
		}
		.p-home-links__item{
			width: 50%;
		}
		.p-home-links__item-link{
			position: relative;
			display: grid;
			place-content: center;
			height: 200px;
			font-size: font-size(18);
			@include trans-normal(background color);
			&:hover{
				background: $clr_bg;
				color: $clr_primary;
			}
		}
	}
}

.main-content{
	display: flex;
	flex-wrap: wrap;
	.content{
		height: 1000px;
	}
}