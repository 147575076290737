@use '../../fundation/index' as *;

/*  blog
---------------------------------------- */
.p-blog-archive{
	padding-bottom: 120px;
	.p-blog-archive__list{
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
	}
	.p-blog-archive__item{
		width: 100%;
	}
	@include mq (medium) {
		padding-bottom: 300px;
		.p-blog-archive__list{
			gap: 50px 10px;
		}
		.p-blog-archive__item{
			width: calc(33.33% - (20px / 3));
		}
	}
	@include mq (large) {
		.p-blog-archive__inner{
			display: grid;
			grid-template-columns: 75.6% auto;
			grid-template-rows: auto 1fr;
			grid-template-areas:
			'archive category'
			'archive keyword';
			gap: 45px 7%;
		}
		.p-blog-archive__category{
			align-self: start;
			grid-area: category;
		}
		.p-blog-archive__keyword{
			align-self: start;
			grid-area: keyword;
		}
		.p-blog-archive__body{
			grid-area: archive;
		}
		.p-blog-archive__list{
			gap: 50px 40px;
		}
		.p-blog-archive__item{
			width: calc(33.33% - (80px / 3));
		}
	}
}