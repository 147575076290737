@use '../fundation/index' as *;

/*  sub-mv
---------------------------------------- */
.l-sub-mv__area{
	position: relative;
	padding: 110px 0 70px;
	@include mq (medium) {
		padding: 280px 0 180px;
	}
}

/*  .l-sub-mv
---------------------------------------- */
.l-sub-mv{
	.l-sub-mv__title-en{
		font-size: font-size(56);
		letter-spacing: .08em;
		line-height: 1.1;
	}
	.l-sub-mv__title-ja{
		margin-top: 10px;
		font-size: font-size(14);
		font-weight: 700;
	}
	.l-sub-mv__title.l-sub-mv__title--large{
		.l-sub-mv__title-ja{
			font-size: font-size(32);
			line-height: 1.6;
			letter-spacing: .1em;
		}
	}
	@include mq (medium) {
		.l-sub-mv__title-en{
			font-size: font-size(120);
		}
		.l-sub-mv__title-ja{
			margin-top: 50px;
			font-size: font-size(22);
		}
		.l-sub-mv__title.l-sub-mv__title--large{
			.l-sub-mv__title-ja{
				font-size: font-size(60);
			}
		}
	}
}