@use '../../fundation/index' as *;
/*  layout
---------------------------------------- */
.c-layout-set01{
	.c-layout-set01__num{
		margin-bottom: 10px;
		color: $clr_primary;
		font-size: font-size(12);
		line-height: 1.1;
	}
	.c-layout-set01__img{
		margin: 10px 0 0 -20px;
	}
	.c-layout-set01__txt-area{
		padding-top: 30px;
	}
	.c-layout-set01__head{
		margin-bottom: 10px;
		color: $clr_primary;
		font-size: font-size(32);
		letter-spacing: .1em;
		line-height: 1.6;
	}
	&.c-layout-set01--rev{
		.c-layout-set01__img{
			margin: 10px -20px 0 0;
		}
	}
	@include mq (medium) {
		display: grid;
		grid-template-columns: 50% auto;
		align-items: center;
		grid-template-areas:
		'img num'
		'img txt';
		gap: 0 7%;
		.c-layout-set01__num{
			grid-area: num;
			align-self: end;
			margin-bottom: 20px;
			font-size: font-size(14);
		}
		.c-layout-set01__img{
			grid-area: img;
			margin: 0;
		}
		.c-layout-set01__txt-area{
			grid-area: txt;
			align-self: start;
			padding-top: 0;
		}
		.c-layout-set01__head{
			margin-bottom: 30px;
			font-size: font-size(37);
		}
		&.c-layout-set01--rev{
			grid-template-columns: auto 50%;
			grid-template-areas:
			'num img'
			'txt img';
		}
	}
}
