@use '../../fundation/index' as *;
/*  single
---------------------------------------- */
.c-single01{
	padding-bottom: 124px;
	.c-single01__article{
		padding-bottom: 70px;
	}
	.c-single01__box{
		margin: 0 -20px;
		padding: 50px 20px 40px;
		background: $clr_bg_secondary;
	}
	.c-single01__head{
		margin-bottom: 30px;
	}
	.c-single01__meta{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px 10px;
		margin-bottom: 15px;
	}
	.c-single01__cats{
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}
	.c-single01__cat{
		padding: 5px 10px;
		background: $clr_bg;
		color: #fff;
		font-size: font-size(12);
	}
	.c-single01__data{
		color: $clr_txt_gray;
		font-size: font-size(13);
	}
	.c-single01__ttl{
		padding-bottom: 15px;
		border-bottom: 1px solid $clr_border;
		font-size: font-size(28);
		line-height: 1.59;
		font-weight: 700;
	}
	.c-single01__tags{
		display: flex;
		flex-wrap: wrap;
		margin-top: 18px;
		gap: 5px 10px;
		font-size: font-size(12);
		color: $clr_txt_gray;
	}
	.c-single01__thumb{
		border: 1px solid $clr_border_secondary;
	}
	.c-single01__cont{
		margin-top: 20px;
		>*{
			margin-top: 25px;
			&:first-child{
				margin-top: 0;
			}
		}
		img{
			width: 100%;
		}
		h2,h3,h4{
			clear: both;
		}
		h2{
			position: relative;
			margin: 60px 0 30px;
			padding-left: 20px;
			font-size: font-size(25);
			line-height: 1.6;
			&::before{
				content: '';
				position: absolute;
				left: 0;
				width: 5px;
				top: 5px;
				bottom: 5px;
				background: $clr_primary;
			}
		}
		h3{
			margin: 30px 0 25px;
			padding-bottom: 15px;
			border-bottom: 1px solid $clr_border_secondary;
			font-size: font-size(22);
			font-weight: 700;
		}
		h4{
			margin: 30px 0 20px;
			font-size: font-size(19);
		}
		blockquote{
			position: relative;
			padding: 25px 40px;
			background: #fff;
			&::before{
				content: '';
				position: absolute;
				left: 15px;
				top: 25px;
				width: 17px;
				height: 15px;
				background: url(../img/common/ico-blockquote.svg) no-repeat center /contain;
			}
		}
		ul,ol{
			list-style: none;
			li{
				position: relative;
				&:nth-child(n+2){
					margin-top: 5px;
				}
			}
		}
		ul{
			li{
				position: relative;
				padding-left: 17px;
				&::before{
					content: '';
					position: absolute;
					left: 0;
					top: 12px;
					width: 6px;
					height: 3px;
					background: $clr_primary;
				}
			}
		}
		ol{
			counter-reset: item;
			li{
				text-indent: -1.4em;
				padding-left: 1.4em;
				&::before{
					counter-increment: item;
					content: counter(item)'. ';
					color: $clr_primary;
					font-size: font-size(15);
					font-weight: 700;
				}
			}
		}
		strong,b{
			font-weight: 700;
			color: #F05C1A;
		}
		a{
			color: $clr_primary;
			&[target="_blank"]{
				&::after{
					content: '';
					display: inline-block;
					margin-left: 10px;
					width: 15px;
					height: 12px;
					background: url(../img/common/ico-blank.svg) no-repeat center /contain;

				}
			}
		}
		.c-single01__note{
			position: relative;
			margin-top: 10px;
			color: $clr_txt_gray;
			font-size: font-size(14);
			text-indent: -1em;
			padding-left: 1em;
			&::before{
				content: '※';
			}
		}
		.c-single01__marker{
			display: inline-block;
			background:linear-gradient(transparent 65%, #B5DAFF 65%);
		}
		.c-single01__cont-box{
			padding: 25px 40px;
			background: #fff;
		}
	}
	.c-single01__sns{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		gap: 20px;
		margin-top: 70px;
	}
	.c-single01__sns-item{
		width: 20px;
	}
	.c-single01__sns-item-in{
		appearance: none;
		border: 0;
		outline: 0;
		background: transparent;
	}
	.c-single01__sns-item-link{
		display: block;
	}
	@include mq (medium) {
		padding-bottom: 297px;
		.c-single01__box{
			margin: 0;
			padding: 80px 90px 50px;
		}
		.c-single01__head{
			margin-bottom: 50px;
		}
		.c-single01__meta{
			gap: 5px 18px;
			margin-bottom: 20px;
		}
		.c-single01__cat{
			padding: 3px 13px;
			font-size: font-size(15);
		}
		.c-single01__data{
			font-size: font-size(15);
		}
		.c-single01__ttl{
			padding-bottom:  35px;
			font-size: font-size(32);
		}
		.c-single01__tags{
			margin-top: 30px;
			gap: 5px 12px;
			font-size: font-size(13);
		}
		.c-single01__thumb{
			width: fit-content;
			margin: 0 auto;
			img{
				max-width: 100%;
				width: auto;
			}
		}
		.c-single01__cont{
			margin-top: 40px;
			> *{
				margin-top: 20px;
			}
			.aligncenter{
				display: block;
				margin: 0 auto;
				text-align: center;
				img{
					display: block;
					margin: 0 auto;
				}
			}
			.alighleft{
				float: left;
				padding: 0 40px 40px 0;
			}
			.alignright{
				float: right;
				padding: 0 0 40px 40px;
			}
			img{
				max-width: 100%;
				width: auto;
			}
			h2{
				margin: 80px 0 40px;
				padding-left: 30px;
				font-size: font-size(28);
				&::before{
					top: 5px;
					bottom: 3px;
				}
			}
			h3{
				margin: 60px 0 30px;
				padding-bottom: 22px;
				font-size: font-size(24);
			}
			h4{
				margin: 60px 0 30px;
				font-size: font-size(20);
			}
			blockquote{
				padding: 28px 70px;
				&::before{
					left: 25px;
					top: 20px;
					width: 24px;
					height: 21px;
				}
			}
			ul{
				li{
					padding-left: 19px;
					&::before{
						top: 13px;
					}
				}
			}
			a{
				&:hover{
					text-decoration: none;
				}
			}
			.c-single01__note{
				margin-top: 20px;
				font-size: font-size(15);
			}
			.c-single01__cont-box{
				padding: 28px 70px;
			}
		}
		.c-single01__sns{
			margin-top:80px;
		}
		.c-single01__sns-item-link{
			@include trans-normal(opacity);
			&:hover{
				opacity: 0.6;
			}
		}
	}
	@include mq (large) {
		.c-single01__inner{
			display: flex;
			flex-wrap: wrap;
			gap: 0 7%;
		}
		.c-single01__article{
			width: 75.7%;
			padding-bottom: 0;
		}
		.c-single01__side{
			display: flex;
			flex-direction: column;
			gap: 70px;
			width: calc(100% - 75.7% - 7%);
		}
	}
}