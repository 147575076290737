@use '../../fundation/index' as *;

/* c-pager01
---------------------------------------- */
.c-pager01{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5px 0;
	margin-top: 40px;
	li{
		width: 45px;
		height: 45px;
		>*{
			display: grid;
			place-content: center;
			width: 100%;
			height: 100%;
		}
		a{
			text-decoration: none;
			@include trans-normal(color);
			&:hover{
				color: $clr_primary;
			}
		}
		+li{
			margin-left: -1px;
		}
	}
	.page,
	.current{
		border: 1px solid $clr_border;
	}
	.current span{
		background: $clr_bg;
		color: #fff;
	}
	.previous a,
	.next a{
		position: relative;
		overflow: hidden;
		text-indent: 90px;
		white-space: nowrap;
		&::before{
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 6px;
			height: 12px;
			background: $clr_bg;
			clip-path: polygon(0 0, 100% 50%, 0 100%);
			@include trans-normal(background);
		}
		&:hover{
			&::before{
				background: $clr_primary;
			}
		}
	}
	.previous a{
		&::before{
			transform: translate(-50%,-50%) scale(-1,1);
		}
	}
	@include mq (medium) {
		margin-top: 80px;
		li{
			width: 50px;
			height: 50px;
		}
		.previous a,
		.next a{
			&::before{
				width: 8px;
				height: 16px;
			}
		}
	}
}

/*  c-pager02
---------------------------------------- */
.c-pager02{
	position: relative;
	margin-top: 50px;
	padding-top: 55px;
	&::before{
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		width: 1px;
		height: 30px;
		background: $clr_border_secondary;
	}
	a{
		text-decoration: none;
	}
	.c-pager02__back{
		width: min(100%,270px);
		margin: 0 auto;
		a{
			display: block;
			padding: 20px;
			border: 1px solid $clr_border;
			text-align: center;
			letter-spacing: .08em;
		}
	}
	.c-pager02__prev,
	.c-pager02__next{
		position: absolute;
		top: 0;
		a{
			position: relative;
			display: block;
			padding: 10px 20px;
			&::before{
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 6px;
				height: 12px;
				background: $clr_default;
				clip-path: polygon(0 0, 100% 50%, 0 100%);
			}
		}
	}
	.c-pager02__prev{
		left: 0;
		a{
			&::before{
				left: 0;
				transform: translateY(-50%) scale(-1,1);
			}
		}
	}
	.c-pager02__next{
		right: 0;
		a{
			&::before{
				right: 0;
			}
		}
	}
	@include mq (medium) {
		margin-top: 80px;
		padding-top: 0;
		&::before{
			content: none;
		}
		.c-pager02__back{
			width: min(100%,350px);
			a{
				padding: 25px;
				@include trans-normal(color border-color);
				&:hover{
					color: $clr_primary;
					border-color: $clr_primary;
				}
			}
		}
		.c-pager02__prev,
		.c-pager02__next{
			top: 50%;
			transform: translateY(-50%);
			a{
				padding: 10px 35px;
				@include trans-normal(color);
				&::before{
					width: 8px;
					height: 16px;
					@include trans-normal(background);
				}
				&:hover{
					color: $clr_primary;
					&::before{
						background: $clr_primary;
					}
				}
			}
		}
	}
}
