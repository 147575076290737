@use '../../fundation/index' as *;

/*  p-about-vision
---------------------------------------- */
.p-about-vision{
	position: relative;
	padding-bottom: 70px;
	z-index: 1;
	&::before{
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		background: $clr_bg_secondary;
		aspect-ratio: 1/1;
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
		z-index: -1;
	}
	.p-about-vision__img{
		margin-left: -20px;
	}
	.p-about-vision__head{
		padding-top: 60px;
		font-size: font-size(56);
		line-height: 1.36;
		letter-spacing: .08em;
	}
	.p-about-vision__txt-area{
		padding: 30px 20px 0;
	}
	.p-about-vision__lead{
		margin-bottom: 25px;
		font-size: font-size(24);
		font-weight: 700;
		line-height: 1.59;
	}
	.p-about-vision__txt{
		line-height: 2;
		+.p-about-vision__txt{
			margin-top: 20px;
		}
	}
	@include mq (large) {
		padding-bottom: 150px;
		&::before{
			width: 66.1%;
		}
		.p-about-vision__inner{
			display: grid;
			grid-template-columns: auto 68.7%;
			grid-template-areas:
			'head img'
			'. txt';
			gap: 70px 7.8%;
		}
		.p-about-vision__img{
			grid-area: img;
			margin-left: 0;
		}
		.p-about-vision__head{
			grid-area: head;
			padding-top: 0;
			font-size: font-size(80);
			line-height: 1.2;
		}
		.p-about-vision__txt-area{
			grid-area: txt;
			padding: 0;
		}
		.p-about-vision__lead{
			margin-bottom: 50px;
			font-size: font-size(30);
		}
		.p-about-vision__txt{
			+.p-about-vision__txt{
				margin-top: 25px;
			}
		}
	}
}

/*  p-about-mission
---------------------------------------- */
.p-about-mission{
	position: relative;
	padding: 70px 20px;
	&::before{
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		left: 0;
		background: $clr_bg;
		z-index: -1;
	}
	.p-about-mission__head{
		margin-bottom: 30px;
		text-align: center;
		color: $clr_txt_white;
		font-size: font-size(56);
		letter-spacing: .08em;
		line-height: 1.1;
	}
	.p-about-mission__head-small{
		font-size: font-size(40);
	}
	.p-about-mission__txt{
		color: $clr_txt_white;
		font-size: font-size(18);
		letter-spacing: .1em;
		line-height: 2.2;
	}
	@include mq (medium) {
		padding: 150px 0;
		&::before{
			left: 60px;
		}
		.p-about-mission__head{
			margin-bottom: 50px;
			text-align: center;
			font-size: font-size(80);
		}
		.p-about-mission__head-small{
			font-size: font-size(50);
		}
		.p-about-mission__txt{
			text-align: center;
			font-size: font-size(22);
		}
	}
}

/*  p-about-value
---------------------------------------- */
.p-about-value{
	padding: 70px 0;
	.p-about-value__head{
		margin-bottom: 20px;
		font-size: font-size(56);
		line-height: 1.2;
		letter-spacing: .08em;
	}
	.p-about-value__list{
		border-top: 1px solid $clr_border_secondary;
	}
	.p-about-value__item{
		padding: 35px 20px;
		border-bottom: 1px solid $clr_border_secondary;
	}
	.p-about-value__item-num{
		font-size: font-size(12);
		line-height: 1.1;
		letter-spacing: .08em;
	}
	.p-about-value__item-head{
		margin: 25px 0;
	}
	.p-about-value__item-head-en{
		font-size: font-size(32);
		line-height: 1.1;
		letter-spacing: .08em;
	}
	.p-about-value__item-head-ja{
		display: inline-block;
		margin-top: 10px;
		padding: 3px 15px;
		font-size: font-size(16);
		@include tagDesign(6)
	}
	.p-about-value__item-img{
		width: 80px;
		margin: 10px 0 0 auto;
	}
	@include mq (medium) {
		padding: 150px 0 180px;
		.p-about-value__head{
			font-size: font-size(80);
		}
		.p-about-value__item{
			display: grid;
			grid-template-columns: auto 1fr 100px;
			//grid-template-rows: ;
			grid-template-areas:
			'num head img'
			'. txt img';
			padding: 65px 20px;
		}
		.p-about-value__item-num{
			grid-area: num;
			padding: 15px 35px 0 0;
			font-size: font-size(14);
		}
		.p-about-value__item-head{
			grid-area: head;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 20px;
			margin: 0 0 25px;
		}
		.p-about-value__item-head-en{
			font-size: font-size(35);
		}
		.p-about-value__item-head-ja{
			margin-top: 0;
			padding: 2px 17px;
		}
		.p-about-value__item-txt{
			grid-area: txt;
		}
		.p-about-value__item-img{
			grid-area: img;
			width: 110px;
			margin: 0;
		}
	}
	@include mq (large) {
		.p-about-value__inner{
			display: flex;
			gap: 0 7.8%;
		}
		.p-about-value__head{
			width: calc(100% - 7.8% - 68.7%);
		}
		.p-about-value__body{
			width: 68.7%;
		}
	}
}

/*  p-about-message
---------------------------------------- */
.p-about-message{
	position: relative;
	padding: 30px 0 70px;
	z-index: 1;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 90px;
		background: $clr_bg_secondary;
		z-index: -1;
	}
	.p-about-message__head{
		margin-bottom: 30px;
		font-size: font-size(56);
		letter-spacing: .08em;
		line-height: 1.1;
	}
	.p-about-message__body-img{
		position: relative;
	}
	.p-about-message__body-img-in{
		border: 1px solid $clr_border;
	}
	.p-about-message__body-img-name-area{
		position: absolute;
		left: 0;
		bottom: -25px;
	}
	.p-about-message__body-img-name-position{
		display: block;
		width: max-content;
		margin-bottom: 2px;
		padding: 5px 15px;
		@include tagDesign(10);
		font-size: font-size(14);
		font-weight: 700;
		letter-spacing: .1em;
	}
	.p-about-message__body-img-name{
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 5px 10px;
		padding: 7px 30px;
		background: $clr_bg;
		letter-spacing: .08em;
		font-weight: 700;
	}
	.p-about-message__body-img-name-ja{
		font-size: font-size(22);
		color: $clr_txt_white;
	}
	.p-about-message__body-img-name-en{
		color: $clr_txt_gray;
		font-size: font-size(10);
		line-height: 1.1;
	}
	.p-about-message__body-txt-area{
		padding-top: 55px;
	}
	.p-about-message__body-txt-lead{
		margin-bottom: 25px;
		font-size: font-size(32);
		letter-spacing: .1em;
		line-height: 1.6;
		font-weight: 700;
	}
	.p-about-message__body-txt-lead-small{
		display: inline-block;
		font-size: font-size(22);
	}
	.p-about-message__body-txt{
		line-height: 1.8;
	}
	@include mq (medium) {
		padding: 120px 0 150px;
		&::before{
			top: 160px;
		}
		.p-about-message__head{
			margin-bottom: 80px;
			font-size: font-size(80);
		}
		.p-about-message__body{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 0 7%;
		}
		.p-about-message__body-img{
			position: relative;
			align-self: flex-start;
			width: 43%;
		}
		.p-about-message__body-img-name-area{
			bottom: 30px;
		}
		.p-about-message__body-img-name-position{
			margin-bottom: 4px;
			padding: 2px 18px;
			@include tagDesign(6);
			font-size: font-size(16);
		}
		.p-about-message__body-img-name{
			gap: 5px 15px;
			padding: 20px 25px;
		}
		.p-about-message__body-img-name-ja{
			font-size: font-size(26);
		}
		.p-about-message__body-img-name-en{
			font-size: font-size(12);
		}
		.p-about-message__body-txt-area{
			width: calc(100% - 7% - 43%);
			padding-top: 0;
		}
		.p-about-message__body-txt-lead{
			margin-bottom: 40px;
			font-size: font-size(37);
		}
	}
}

/* p-about-service
---------------------------------------- */
.p-about-service{
	position: relative;
	padding: 70px 0 110px;
	.p-about-service__bg{
		position: absolute;
		top: -10px;
		left: -20px;
		transform: translateY(-100%) rotate(90deg);
		transform-origin: left bottom;
		font-size: font-size(75);
		color: $clr_bg_secondary;
		line-height: 1.1;
		z-index: -1;
	}
	.p-about-service__head{
		margin-bottom: 45px;
		font-size: font-size(56);
		letter-spacing: .08em;
		line-height: 1.2;
	}
	.p-about-service__head-name{
		font-size: font-size(35);
	}
	.p-about-service__sec{
		&:nth-child(n+2){
			margin-top: 50px;
		}
	}
	.p-about-service__sec-example{
		margin-top: 25px;
	}
	.p-about-service__sec-example-head{
		margin-bottom: -1px;
		padding: 10px 20px;
		border: 1px solid $clr_border_secondary;
		text-align: center;
		font-size: font-size(18);
	}
	.p-about-service__sec-example-list{
		display: flex;
		flex-wrap: wrap;
		border: solid $clr_border_secondary;
		border-width: 1px 0 0 1px;
	}
	.p-about-service__sec-example-item{
		width: 50%;
		padding: 20px 25px;
		border: solid $clr_border_secondary;
		border-width: 0 1px 1px 0;
	}
	.p-about-service__sec-example-img{
		width: 40px;
		margin: 0 auto;
	}
	.p-about-service__sec-example-txt{
		margin-top: 15px;
		text-align: center;
		font-size: font-size(14);
		font-weight: 700;
	}
	.p-about-service__sec-example-txt-small{
		font-weight: 500;
	}
	@include mq (medium) {
		padding: 180px 0;
		.p-about-service__bg{
			top: -5px;
			left: -30px;
			font-size: font-size(200);
		}
		.p-about-service__head{
			margin-bottom: 80px;
			font-size: font-size(80);
		}
		.p-about-service__head-name{
			font-size: font-size(40);
		}
		.p-about-service__sec{
			&:nth-child(n+2){
				margin-top: 100px;
			}
		}
		.p-about-service__sec-example{
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;
			border: solid $clr_border_secondary;
			border-width: 1px 0 0 1px;
		}
		.p-about-service__sec-example-head{
			display: grid;
			place-content: center;
			width: 25%;
			margin-bottom: 0;
			font-size: font-size(18);
			border-width: 0 1px 1px 0;
		}
		.p-about-service__sec-example-list{
			width: 75%;
			border: 0;
		}
		.p-about-service__sec-example-item{
			width: 33.333%;
			padding: 40px 25px;
		}
		.p-about-service__sec-example-img{
			width: 50px;
		}
		.p-about-service__sec-example-txt{
			font-size: font-size(15);
		}
	}
}

/*  p-about-company
---------------------------------------- */
.p-about-company{
	position: relative;
	margin-top: -60px;
	padding: 90px 0 120px;
	background: $clr_bg_secondary;
	&::before{
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		transform: translateY(-100%);
		width: 100%;
		aspect-ratio: 1/1;
		background: $clr_bg_secondary;
		clip-path: polygon(100% 0, 100% 100%, 0 100%);
		z-index: -1;
	}
	.p-about-company__box{
		position: relative;
		padding: 50px 20px;
		z-index: 1;
		&::before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			height: 100%;
			background: #fff;
			z-index: -1;
		}
	}
	.p-about-company__head{
		position: absolute;
		left: 50%;
		transform: translate(-50%,-50%);
		top: 0;
		width: 100%;
		text-align: center;
		font-size: font-size(40);
		line-height: 1.1;
		letter-spacing: .08em;
	}
	@include mq (medium) {
		margin-top: -110px;
		padding: 145px 0 300px;
		.p-about-company__head{
			font-size: font-size(80);
		}
	}
	@include mq (large) {
		.p-about-company__box{
			padding: 120px 15% 150px;
			&::before{
				left: -320px;
				right: -200px;
			}
		}
	}
}