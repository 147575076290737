@use '../../fundation/index' as *;

.u-view-small{
	@include mq (medium){
		display: none !important;
	}
}
.u-view-medium{
	@include mq (small) {
		display: none !important;
	}
	@include mq (large) {
		display: none !important;
	}
}
.u-view-large{
	@media all and (max-width: ($contents_width_with_padding - 1)){
		display: none !important;
	}
}
.u-view-under-medium{
	@include mq (large) {
		display: none !important;
	}
}
.u-view-upper-medium{
	@include mq (small) {
		display: none !important;
	}
}