@use '../../fundation/index' as *;

/*  p-policy-privacy
---------------------------------------- */
.p-policy-privacy{
	padding-bottom: 120px;
	line-height: 1.8;
	.p-policy-privacy__lead{
		border: 1px solid $clr_border;
		padding: 20px 25px;
	}
	.p-policy-privacy__list{
		margin-top: 50px;
	}
	.p-policy-privacy__item{
		&:nth-child(n+2){
			margin-top: 50px;
		}
	}
	.p-policy-privacy__item-head{
		margin-bottom: 20px;
		font-size: font-size(19);
	}
	.p-policy-privacy__item-head-num{
		display: inline-block;
		margin-right: 10px;
		color: $clr_primary;
		font-size: font-size(20);
		line-height: 1.6;
	}
	.p-policy-privacy__item-txt{
		&:nth-child(n+2){
			margin-top: 1em;
		}
	}
	.p-policy-privacy__item-num{
		margin-top: 15px;
	}
	.p-policy-privacy__item-num-item{
		padding-left: 1.6em;
		&:nth-child(n+2){
			margin-top: 15px;
		}
		*{
			text-indent: 0;
			padding-left: 0;
		}
		.p-policy-privacy__item-num-item-txt{
			text-indent: -1.6em;
		}
		.p-policy-privacy__item-sub-item{
			padding-left: 17px;
			text-indent: -17px;
		}
		.p-policy-privacy__item-note-item{
			padding-left: 1.2em;
			text-indent: -1.2em;
		}
	}
	.p-policy-privacy__item-sub{
		margin-top: 10px;
		font-size: font-size(14);
	}
	.p-policy-privacy__item-sub-item{
		&:nth-child(n+2){
			margin-top: 10px;
		}
	}
	.p-policy-privacy__item-note{
		margin-top: 15px;
	}
	.p-policy-privacy__item-note-list{
		margin-top: 5px;
		font-size: font-size(14);
	}
	.p-policy-privacy__item-note-item{
		&:nth-child(n+2){
			margin-top: 10px;
		}
	}
	@include mq (medium) {
		padding-bottom: 290px;
		.p-policy-privacy__lead{
			padding: 40px 45px;
		}
		.p-policy-privacy__list{
			margin-top: 100px;
		}
		.p-policy-privacy__item{
			&:nth-child(n+2){
				margin-top: 80px;
			}
		}
		.p-policy-privacy__item-head{
			font-size: font-size(20);
		}
		.p-policy-privacy__item-head-num{
			margin-right: 13px;
			font-size: font-size(22);
		}
		.p-policy-privacy__item-num{
			margin-top: 25px;
		}
		.p-policy-privacy__item-num-item{
			&:nth-child(n+2){
				margin-top: 18px;
			}
			.p-policy-privacy__item-sub-item{
				padding-left: 17px;
				text-indent: -17px;
			}
		}
		.p-policy-privacy__item-sub{
			font-size: font-size(15);
		}
		.p-policy-privacy__item-sub-item{
			&:nth-child(n+2){
				margin-top: 5px;
			}
		}
		.p-policy-privacy__item-note-list{
			margin-top: 10px;
			font-size: font-size(15);
		}
		.p-policy-privacy__item-note-item{
			&:nth-child(n+2){
				margin-top: 5px;
			}
		}
	}
}