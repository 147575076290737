@use './variable' as *;

/*  base
================================================ */
html {
	overflow: auto;
	&:not([lang="ja"]) {
		word-break: break-word;
	}
}
body {
	overflow: hidden;
	min-width: 320px;
	@include font_base();
	@include mq(medium) {
		min-width: auto;
		font-size: font-size(16);
	}
}