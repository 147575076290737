@use '../../fundation/index' as *;
/*  SP左右余白あり、PCコンテンツ幅
---------------------------------------- */
.c-inner01{
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 20px;
	@include mq(large) {
		width: 100%;
		max-width: $contents_width_with_padding;
		margin: auto;
		padding: 0;
	}
}
.c-inner01.c-inner01--large{
	@include mq(large) {
		max-width: 1680px;
		padding: 0 24px;
	}
}
.c-inner01.c-inner01--small{
	@include mq(medium) {
		max-width: 1010px;
		margin: 0 auto;
		padding: 0 20px;
	}
}

/*  SP左右余白あり、PCはデバイス幅いっぱい
---------------------------------------- */
.c-inner02{
	box-sizing: border-box;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	@include mq(large) {
		max-width: min(100%,1920px);
		margin: auto;
		padding-left: 0;
		padding-right: 0;
	}
}

/*  SP左右余白「なし」、PCコンテンツ幅
---------------------------------------- */
.c-inner03{
	box-sizing: border-box;
	width: 100%;
	@include mq(large) {
		max-width: min(100%,1920px);
		margin: auto;
		padding-left: 0;
		padding-right: 0;
	}
}