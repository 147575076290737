@use '../../fundation/index' as *;
.c-tax-select{
	width: min(100%,300px);
	margin: 0 auto 35px;
	padding: 15px 40px 15px 20px;
	background: url(../img/common/ico-select.svg) no-repeat center right 20px/ 12px auto;
	border: 1px solid $clr_border;
	cursor: pointer;
	appearance: none;
	&::-ms-expand {
			display: none;
	}
}

/*  c-tax-keyword
---------------------------------------- */
.c-tax-keyword{
	margin-top: 70px;
	.c-tax-keyword__head{
		position: relative;
		display: inline-block;
		margin-bottom: 12px;
		padding: 5px 15px;
		color: #fff;
		font-size: font-size(13);
		letter-spacing: .08em;
		line-height: 1.5;
		@include tagDesign(6);
	}
	.c-tax-keyword__list{
		display: flex;
		flex-wrap: wrap;
		gap: 10px 15px;
		padding: 25px;
		border: 1px solid $clr_border_secondary;
	}
	.c-tax-keyword__item--current{
		.c-tax-keyword__item-link{
			color: $clr_primary;
		}
	}
	.c-tax-keyword__item-link{
		font-size: font-size(14);
		line-height: 1.5;
		color: $clr_txt_gray;
	}
	@include mq (large) {
		margin-top: 0;
		.c-tax-keyword__head{
			margin-bottom: 15px;
			padding: 8px 17px;
			@include tagDesign(7);
			font-size: font-size(14);
		}
		.c-tax-keyword__list{
			gap: 5px 15px;
			padding: 0;
			border: 0;
		}
		.c-tax-keyword__item-link{
			@include trans-normal(color);
			&:hover{
				color: $clr_primary;
			}
		}
	}
}

/*  c-tax-category
---------------------------------------- */
.c-tax-category{
	.c-tax-category__head{
		position: relative;
		display: inline-block;
		margin-bottom: 12px;
		padding: 5px 15px;
		color: #fff;
		font-size: font-size(13);
		letter-spacing: .08em;
		line-height: 1.5;
		@include tagDesign(6);
	}
	.c-tax-category__list{
		border-top: 1px solid $clr_border_secondary;
	}
	.c-tax-category__item{
		border-bottom: 1px solid $clr_border_secondary;
	}
	.c-tax-category__item-link{
		position: relative;
		display: block;
		padding: 20px 20px 20px 38px;
		font-size: font-size(14);
		@include trans-normal(opacity);
		&::before{
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;
			width: 5px;
			height: 10px;
			background: $clr_border_secondary;
			clip-path: polygon(0 0, 100% 50%, 0 100%);
			@include trans-normal(background);
		}
		&:hover{
			opacity: 0.7;
		}
	}
	.c-tax-category__item--current{
		.c-tax-category__item-link{
			color: $clr_primary;
			font-weight: 700;
			&::before{
				background: $clr_primary
				;
			}
		}
	}
	@include mq (medium) {
		.c-tax-category__head{
			margin-bottom: 15px;
			padding: 8px 17px;
			@include tagDesign(7);
			font-size: font-size(14);
		}
	}
}