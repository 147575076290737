@use '../../fundation/index' as *;
.p-contact-form{
	padding-bottom: 124px;
	.p-contact-form__required{
		margin-bottom: 10px;
	}
	.p-contact-form__box{
		margin: 0 -20px;
		padding: 50px 20px;
		background: $clr_bg_secondary;
	}
	@include mq (medium) {
		padding-bottom: 290px;
		.p-contact-form__required{
			margin-bottom: 20px;
		}
		.p-contact-form__box{
			margin: 0;
			padding: 80px 90px;
		}
	}
}