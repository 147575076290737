@use '../../fundation/index' as *;
/* c-btn01
---------------------------------------- */
.c-btn01{
	width: min(100%,270px);
	margin: 40px auto 0;
	.c-btn01__link{
		overflow: hidden;
		position: relative;
		display: block;
		padding: 20px;
		border: 1px solid $clr_default;
		text-align: center;
		font-size: font-size(15);
		letter-spacing: .08em;
		&::before{
			content: '';
			position: absolute;
			right: -1px;
			bottom: -1px;
			width: 14px;
			height: 14px;
			background: $clr_primary;
			clip-path: polygon(100% 0, 100% 100%, 0 100%);
		}
	}
	@include mq (medium) {
		width: min(100%,500px);
		margin-top: 100px;
		.c-btn01__link{
			padding: 35px 40px;
			font-size: font-size(18);
			@include trans-normal(border-color color);
			&:hover{
				color: $clr_primary;
				border-color: $clr_primary;
			}
			&::before{
				width: 21px;
				height: 21px;
			}
		}
	}
}

/*  c-btn02
---------------------------------------- */
.c-btn02{
	margin-top: 40px;
	.c-btn02__link{
		position: relative;
		display: inline-block;
		padding-right: 25px;
		letter-spacing: .08em;
		font-size: font-size(15);
		&::before{
			content: '';
			position: absolute;
			right: 0;
			bottom: .3em;
			width: 11px;
			height: 11px;
			background: $clr_primary;
			clip-path: polygon(100% 0, 100% 100%, 0 100%);
		}
	}
	@include mq (medium) {
		margin-top: 60px;
		.c-btn02__link{
			padding-right: 35px;
			font-size: font-size(17);
			@include trans-normal(color);
			&:hover{
				color: $clr_primary;
			}
			&::before{
				width: 13px;
				height: 13px;
			}
		}
	}
}
