@charset "UTF-8";

/*  color
================================================ */
$clr_default: #0E101C;
$clr_primary: #0077EF;
$clr_txt_gray: #616165;
$clr_txt_white: #F7F7F7;
$clr_border: #0E101C;
$clr_border_secondary: #DBDBDB;
$clr_bg: #1D1D21;
$clr_bg_secondary: #F7F7F7;
//$shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);

/*  font
================================================ */
@function font-size($font-size-px) {
  @return calc($font-size-px / 16 * 1rem);
}

@mixin font_base(){
	font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
	color: $clr_default;
	line-height: 1.6;
	letter-spacing: .05em;
	font-weight: 400;
	font-size: font-size(15);
}

/*  line-height
================================================ */
//@mixin line-height( $font: 15 ,$line: 24 ){
//	line-height: $line / $font;
//}

/*  clamp vwの計算
================================================ */
@function clampVw($min, $size, $max, $vp: 1920) {
  $min: $min * 0.1rem;
  $max: $max * 0.1rem;
  $num: 100 / $vp * 1vw;
	$size_vw: $num * $size;
	@return clamp($min, $size_vw, $max);
}

/*  transition
================================================ */
@mixin trans-normal( $prop: all ){
	transition: .2s cubic-bezier(0.71, 0.18, 0.3, 0.73);
	transition-property: $prop;
}

/*  width
================================================ */
$contents_width: 1280px;
$contents_width_with_padding: 1320px;

// breakpoints
$breakpoints: (
	'xSmall': 'all and (max-width: 360px)',
	'small': 'all and (max-width: 767px)',
	'medium': 'all and (min-width: 768px)',
	'large': 'all and (min-width: '+ $contents_width_with_padding + ')',
	'xLarge': 'all and (min-width: ' + ($contents_width_with_padding + 400) + ')',
) !default;
@mixin mq($breakpoint: tb) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

/*  tag design
================================================ */
@mixin tagDesign ($size: 6){
	position: relative;
	z-index: 1;
	color: #fff;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: $clr_bg;
		z-index: -1;
		clip-path: polygon(0 0, 100% 0, 100% calc(100% - #{$size}px), calc(100% - #{$size}px) 100%, 0 100%);
	}
}
