@use '../../fundation/index' as *;
/* js-fadein-img
---------------------------------------- */
.js-fadein-img{
	opacity: 0;
	clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
	transition: all .6s ease-in-out;
	&.is-active{
		opacity: 1;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}
.js-fadein-item{
	clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
	transition:	all 2s cubic-bezier(.19,1,.22,1);
	&.is-active{
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	}
}