@use '../fundation/index' as *;
.l-footer{
	position: relative;
	padding-top: 50px;
	z-index: 1;
	&::before{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: $clr_bg;
		z-index: -1;
	}
	.l-footer__info{
		padding-bottom: 50px;
	}
	.l-footer__info-logo{
		width: 168px;
	}
	.l-footer__info-logo-link{
		display: block;
	}
	.l-footer__info-add{
		margin-top: 15px;
		font-size: font-size(13);
		color: $clr_txt_gray;
		line-height: 1.56;
	}
	.l-footer__contact{
		margin-top: 35px;
		color: $clr_txt_white;
	}
	.l-footer__contact-txt{
		font-size: font-size(14);
	}
	.l-footer__contact-btn{
		margin-top: 20px;
	}
	.l-footer__cr{
		border-top: 1px solid #252529;
		padding: 15px 0;
		text-align: center;
		color: $clr_txt_gray;
		font-size: font-size(10);
	}
	@include mq (medium) {
		padding-top:120px;
		.l-footer__inner{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 120px;
		}
		.l-footer__info{
			width: 450px;
			padding-bottom: 0;
		}
		.l-footer__nav{
			width: calc(100% - 450px);
		}
		.l-footer__info-add{
			margin-top: 30px;
		}
		.l-footer__contact{
			margin-top: 60px;
		}
		.l-footer__contact-txt{
			font-size: font-size(15);
		}
		.l-footer__contact-btn{
			margin-top: 30px;
		}
		.l-footer__nav-list{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			gap: 25px;
			color: $clr_txt_white;
		}
		.l-footer__nav-item-link{
			display: block;
			padding: 5px 10px;
			@include trans-normal(opacity);
			&:hover{
				opacity: 0.5;
			}
		}
		.l-footer__cr{
			padding: 20px 0;
		}
	}
}
/*
---------------------------------------- */
.l-footer__en{
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(-83%);
	overflow: hidden;
	display: flex;
	width: 100%;
	margin-bottom: -0.5em;
	z-index: -2;
	.l-footer__en-in{
		display: inline-block;
		padding-right: 0.5em;
		white-space: nowrap;
		font-size: font-size(45);
		line-height: 1.1;
		transform: rotate(0.1deg);
		&:nth-child(1){
			animation: loop 80s -40s linear infinite;
		}
		&:nth-child(2){
			animation: loop2 80s linear infinite;
		}
	}
	@include mq (medium) {
		margin-bottom: -1.1em;
		.l-footer__en-in{
			font-size: font-size(100);
		}
	}
}
@keyframes loop {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes loop2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}