@use '../fundation/index' as *;
/*  l-header
---------------------------------------- */
.l-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 60px;
	z-index: 100;
	&.is-active{
		position: fixed;
		background-color: #fff;
	}
	.l-header__info{
		position: relative;
		height: 100%;
		padding: 5px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		background: #fff;
		z-index: 2;
	}
	.l-header__logo{
		width: 101px;
	}
	.l-header__logo-link{
		display: block;
	}
	.l-header__nav-btn{
		position: relative;
		width: 32px;
		height: 32px;
		background: #fff;
	}
	.l-header__nav-btn-line{
		position: absolute;
		top: 0;
		right: 0;
		height: 2px;
		background: $clr_default;
		@include trans-normal();
		&:nth-child(1){
			top: 5px;
			width: 16px;
		}
		&:nth-child(2){
			top: 15px;
			width: 24px;
		}
		&:nth-child(3){
			top: 25px;
			width: 32px;
		}
	}
	.l-header__nav-btn.is-open{
		.l-header__nav-btn-line{
			&:nth-child(1){
				width: 35px;
				top: 15px;
				rotate: 45deg;
			}
			&:nth-child(2){
				opacity: 0;
			}
			&:nth-child(3){
				width: 35px;
				top: 15px;
				rotate: -45deg;
			}
		}

	}
	@include mq (large) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		height: 140px;
		padding: 80px 4% 0px;
		&.is-active{
			padding-top: 50px;
			height: 110px;
		}
		.l-header__info,
		.l-header__logo{
			width: 169px;
		}
		.l-header__nav-btn{
			display: none;
		}
	}
}

/*  l-nav
---------------------------------------- */
.l-nav{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	display: none;
	//display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding-top: 100px;
	background: $clr_bg;
	z-index: 1;
	.l-nav__list{
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	.l-nav__item{
		text-align: center;
		color: $clr_txt_white;
	}
	.l-nav__item-link{
		display: block;
		padding: 20px 0;
		letter-spacing: .08em;
		font-size: font-size(30);
	}
	@include mq (large) {
		display: block !important;
		position: static;
		top: 0;
		left: 0;
		right: auto;
		height: auto;
		display: flex;
		background: transparent;
		padding-top: 0;
		.l-nav__list{
			flex-direction: row;
			gap: 40px;
		}
		.l-nav__item{
			color: $clr_default;
		}
		.l-nav__item-link{
			padding: 0;
			font-size: font-size(16);
			@include trans-normal(color);
			&:hover{
				color: $clr_primary;
			}
		}
	}
}