@use '../../fundation/index' as *;
/*  breadcrumb
---------------------------------------- */
.c-breadcrumb{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 20px;
	color: $clr_txt_gray;
	font-size: font-size(13);
	span,a{
		display: block;
	}
	a{
		text-decoration: none;
		display: block;
		color: $clr_default;
		@include trans-normal(opacity);
		&:hover{
			opacity: 0.7;
		}
	}
	.c-breadcrumb__item{
		display: flex;
		align-items: center;
		&:nth-child(n+2){
			margin-left: 12px;
			&::before{
				content: '';
				display: inline-block;
				width: 6px;
				height: 12px;
				margin-right: 12px;
				background: $clr_border_secondary;
				clip-path: polygon(0 0, 100% 50%, 0 100%);
			}
		}
	}
}