@use '../../fundation/index' as *;
/*  table
---------------------------------------- */
.c-table01{
	table{
		table-layout: fixed;
		border: solid $clr_border;
		border-width: 1px 0 0 1px;
	}
	th,td{
		display: block;
		padding: 20px 25px;
		border: solid $clr_border;
		border-width: 0 1px 1px 0;
		text-align: left;
	}
	th{
		background: $clr_bg;
		color: #fff;
		font-size: font-size(15);
		font-weight: 700;
	}
	td{
		line-height: 1.8;
	}
	@include mq (medium) {
		table{
			border: 0;
		}
		th,td{
			display: table-cell;
			padding: 35px 40px;
			border: 1px solid $clr_border;
		}
		th{
			width: 25%;
			text-align: center;
			font-size: font-size(16);
			border-bottom: 1px solid $clr_txt_gray;
		}
	}
}